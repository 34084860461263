import React, { useEffect, useState } from "react";
import {
  Header,
  Button,
  Table,
  Status,
  SidePanel,
  Snackbar,
} from "iosis-storybook/dist/bundle";
import { FaPlus } from "react-icons/fa6";
import { IoStarOutline } from "react-icons/io5";

const Offers = () => {
  const [offersArchived, setOffersArchived] = useState(false);
  const PricingType = {
    ONESHOT: "",
    MONTH: "/Mois",
    YEAR: "/An",
  };

  const StatusList = {
    ACTIVE: { type: "complete", title: "Activé" },
    DRAFT: { type: "draft", title: "Brouillon" },
    DISABLED: { type: "danger", title: "Désactivé" },
  };

  const offerList = [
    {
      id: "1234567890",
      name: "Basic",
      startDate: "23 Décembre 2024",
      endDate: "23 Décembre 2024",
      pricing: [
        { periodType: "ONESHOT", currency: "€", price: 200 },
        { periodType: "MONTH", currency: "€", price: 200 },
      ],
      status: "DRAFT",
    },
    {
      id: "1134567890",
      name: "Basic",
      startDate: "23 Décembre 2024",
      endDate: "23 Décembre 2024",
      pricing: [{ periodType: "ONESHOT", currency: "€", price: 200 }],
      status: "ACTIVE",
    },
    {
      id: "1244567890",
      name: "Basic",
      startDate: "23 Décembre 2024",
      endDate: "23 Décembre 2024",
      pricing: [{ periodType: "ONESHOT", currency: "€", price: 200 }],
      status: "DISABLED",
    },
  ];
  const columns = [
    {
      key: "id",
      label: "référence",
    },
    {
      key: "name",
      label: "Nom de l'offre",
    },
    {
      key: "startDate",
      label: "Date de début",
    },
    {
      key: "endDate",
      label: "Date de fin",
    },
    {
      key: "pricing",
      label: "Tarif",
      getter: (value) => (
        <div className="columnn">
          {value.map((el) => (
            <span>
              {el.price + " " + el.currency + PricingType[el.periodType]}
            </span>
          ))}
        </div>
      ),
    },
    {
      key: "status",
      label: "Statut",
      getter: (value) => (
        <Status
          type={StatusList[value].type}
          title={StatusList[value].title}
          forTable
        />
      ),
    },
    {
      key: "status",
      label: "Action",
      getter: (status) => {
        if (status === "DISABLED") {
          return (
            <Button
              styled="tertiary"
              label="Aucune action"
              onClick={(e) => e.stopPropagation()}
              disabled
            />
          );
        } else if (status === "ACTIVE") {
          return (
            <Button
              styled="tertiary"
              label="Désactiver"
              onClick={(e) => e.stopPropagation()}
            />
          );
        } else if (status === "DRAFT") {
          return (
            <Button
              styled="tertiary"
              label="Compléter"
              onClick={(e) => e.stopPropagation()}
            />
          );
        }
      },
    },
  ];

  const onArchive = () => {
    setOffersArchived(true);
  };

  return (
    <div className="contract-page-container">
      {offersArchived && (
        <Snackbar
          type="success"
          text="L'offre a été archivée avec succès"
          size="small"
          withLink={true}
          textLink="Annuler"
          autoClose={8000}
        />
      )}
      <Header
        title="Offres"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit donec."
        titleIcon={<IoStarOutline color="#62AF98" />}
        primaryButton={
          offerList.length > 0 && (
            <Button
              styled="primary"
              label="Créer une offre"
              startIcon={<FaPlus />}
              onClick={() => {}}
              size={"md"}
            />
          )
        }
      />
      <div className="container">
        <div>
          <Table
            pageSize={10}
            setPageSize={() => {}}
            currentPage={1}
            setCurrentPage={() => {}}
            pageSizes={[10, 20, 50]}
            entriesCount={offerList.length}
            columns={columns}
            data={offerList}
            withSelect
            onRowClick={() => {}}
            onArchive={onArchive}
            emptyPageTitle={"Il n’y a aucune offre dans la liste"}
            emptyPageDesc={"Cliquez sur “Créer une offre” pour commencer"}
            onClickEmptyPage={() => {}}
            emptyPageTextButton={"Créer une offre"}
          />
        </div>
      </div>
    </div>
  );
};

export default Offers;
