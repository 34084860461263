import React, { useEffect, useState } from "react";
// import "./personnes.scss";
import { Select, Button, Accordeon, InputWithActions, SwitchButton } from "iosis-storybook/dist/bundle";
import { IoMdAdd } from "react-icons/io";
import noRelation from "../../img/no-relations.svg";
import PropTypes from "prop-types";
import { getCurrentDay } from "../../utils/functions";

const relationGenders = {
    entreprise:"Entreprise",
    person:"Personne"
}

const Relations = ({selectedElement, setSelectedElement, relations}) => {
    const [addRelation, setAddRelation] = useState(false)
    const [relationGender, setRelationGender] = useState(relationGenders.entreprise)
    const [relationType, setRelationType] = useState(false)
    const [relationWith, setRelationWith] = useState(false)

    const handleAddRelation = () =>{
        setSelectedElement({...selectedElement, relations: {relationType, relationWith, createdAt: getCurrentDay()}})
        setAddRelation(false)
    }

    const accordionInfos =()=> {
        if(relations && relations.length>0){
            return <>
                <div>
                    {relations.map((relation, index)=><div key={index} className="relations">
                        {relation.hasOwnProperty('property1') ? <div className="relation-names text-sm-semibold color-primary-700">
                            {selectedElement.gender === "Monsieur" ? "Mr " : "Mme "}
                            {selectedElement.firstName}{" "}
                            {selectedElement.lastName}
                        </div>
                        :
                        <div className="relation-names text-sm-semibold color-primary-700">
                            {relation.relationWith}
                        </div>
                        }
                        <div className="relation-types">
                                <div className="text-xs-bold color-neutral-600">Type de relation</div>
                                <div className="text-sm-regular color-neutral-1000 ">{relation.relationType}</div>
                                <div className="text-xs-bold color-neutral-600 mt-2">Date de création de la relation</div>
                                <div className="text-sm-regular color-neutral-1000">{relation.createdAt}</div>
                        </div>
                    </div>
                    )}
                </div>
            
            {addRelation &&
            <div className="mt-5">
                <div className="coll-6">
                    <SwitchButton
                    label1="Entreprise"
                    label2="Personne"
                    onSwitch={(value) => {
                        setRelationGender(value);
                    }}
                    />
                </div>
                <div className="content-input">
                    <Select 
                        label={"Type de relation"}
                        dataList={[
                            { id: 1, value: "Femme", label: "Femme" },
                            { id: 2, value: "Fille", label: "Fille" },
                        ]}
                        placeholder={"Aucune relation sélectionnée"}
                        disabled={false}
                        onSelectItem={(el) => {
                            setRelationType(el.value)
                        }}
                    />
                </div>
                <div className="content-input">
                    <Select 
                        label={"Avec"}
                        dataList={[
                            { id: 1, value: "Cristiano Ronaldo", label: "Cristiano Ronaldo" },
                            { id: 2, value: "Diego Armando", label: "Diego Armando" },
                            { id: 2, value: "VinSmoke Sanji", label: "VinSmoke Sanji" },
                            { id: 2, value: "Yagami Raito", label: "Yagami Raito" },
                        ]}
                        placeholder={"Choisir parmi la liste"}
                        disabled={false}
                        onSelectItem={(el) => {
                            setRelationWith(el.value)
                        }}
                    />
                </div>
                <div className="content-input">
                    <Button styled="primary" label="Ajouter une relation" onClick={()=>{handleAddRelation()}} endIcon={<IoMdAdd/>}/>   
                    <Button styled="tertiary" label="Annuler" onClick={()=>{setAddRelation(false)}}/>
                </div>
            </div>}
            </>
            }else{

            (
            
            <div className="relation-empty-accordion">
                <div className="relation-empty-accordion mt-5"><img src={noRelation} alt={"relations"}/></div>
                <div className="relation-empty-accordion text-xs-medium mt-5">Il n’y a actuellement aucune relation avec cette personne</div>
                <div className="relation-empty-accordion relation-button mt-5">
                    <Button 
                        styled="primary" 
                        label="Ajouter une relation" 
                        onClick={()=>setAddRelation(true)}
                        startIcon={<IoMdAdd/>}
                    />
                </div>
            </div>)
            
        }
    }

    return (
        <div>
            <Accordeon 
                title={`Relations (${relations.length>0 ? relations.length : 0})`}
                isChild
                children={accordionInfos()}
                action={<Button
                    label="Ajouter"
                    styled="tertiary"
                    size="sm"
                    startIcon={<IoMdAdd size={22} />}
                    onClick={() => {
                        setAddRelation(true);
                    }}
                  />}
            />
        </div>
    );
};

export default Relations;

Relations.prototype = {
    relations: PropTypes.object,
    selectedElement: PropTypes.object,
    setSelectedElement: PropTypes.func,
  };