import React from "react";

function Dropdown({ ...props }) {
  return (
    <div>
      {props.label && <label>{props.label}</label>}
      <div style={{ width: props.width && props.width === true && "auto" }}>
        <select
          className="filled-input"
          disabled={props.disableInput}
          value={props.value}
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
        >
          {props.options.map((op: any) => (
            <option key={op.id} className="custom-input" value={op.value}>
              {op.label}
            </option>
          ))}
        </select>
      </div>
      {props.errorMsg && (
        <span style={{ color: "red", fontSize: "12px" }}>{props.errorMsg}</span>
      )}
    </div>
  );
}

export default Dropdown;
