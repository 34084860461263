import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import Button from "../../../../components/ui/Button";
import Input from "../../../../components/ui/Input";
import { updateQuote } from "../services";
import image from "../../../../img/iosis-logo.svg";
import { MdOutlinePhone } from "react-icons/md";
import {
  DatePicker,
  HelpBox,
  Input as Inputt,
} from "iosis-storybook/dist/bundle";

const ProductInfo = ({
  setStep,
  quoteId,
  quoteDetails,
  setQuoteUpdated,
  ...props
}) => {
  const [vehiculeData, setVehiculeData] = useState({});

  useEffect(() => {
    if (!!quoteDetails?.productData) {
      setVehiculeData(quoteDetails.productData);
    } else {
      setVehiculeData({});
    }
  }, [quoteDetails]);

  const handleSubmit = () => {
    updateQuote(quoteId, { productData: vehiculeData })
      .then((resp) => {
        setStep(3);
        setQuoteUpdated((prev) => {
          return !prev;
        });
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="roww gapp-6">
      <div className="coll-9">
        <div className="roww align-center">
          <FaArrowLeft
            size={16}
            color="#3D7D65"
            className="mr-3"
            onClick={() => {
              setStep(1);
            }}
          />

          <span className="text-md-medium">
            Étape 2: informations véhicules
          </span>
        </div>
        <hr></hr>
        <div className="roww gapp-5">
          <div className="coll-6">
            <Inputt
              label="Marque"
              placeholder="BMW"
              value={vehiculeData.marque}
              onChange={(value) => {
                setVehiculeData({ ...vehiculeData, marque: value });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Modèle"
              placeholder="Série 3"
              value={vehiculeData.model}
              onChange={(value) => {
                setVehiculeData({ ...vehiculeData, model: value });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Énergie"
              placeholder="test"
              value={vehiculeData.energy}
              onChange={(value) => {
                setVehiculeData({ ...vehiculeData, energy: value });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Puissance (CV)"
              placeholder="90"
              value={vehiculeData.puissance}
              onChange={(value) => {
                setVehiculeData({ ...vehiculeData, puissance: value });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Numéro de plaque d’immatriculation"
              placeholder=" AA-001-AA"
              value={vehiculeData.car_plate}
              onChange={(value) => {
                setVehiculeData({ ...vehiculeData, car_plate: value });
              }}
            />
          </div>
          <div className="coll-6">
            <DatePicker
              label="Date de mise en circulation"
              years={[
                2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009,
                2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019,
                2020, 2021, 2022, 2023, 2024,
              ]}
            />
          </div>
        </div>

        {/* <div className="d-flex flex-column gap-5">
          <span className="d-flex gap-3 align-items-center">
            <FaArrowLeft
              size={36}
              cursor={"pointer"}
              onClick={() => {
                setStep(1);
              }}
            />
            <h2>Étape 2: informations véhicule</h2>
          </span>

          <div className="col-8 d-flex flex-column gap-2">
            <div className="d-flex flex-column gap-4">
              <div className="d-flex justify-content-between">
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Marque"}
                    placeholder={"Marque"}
                    styled={"filled-input"}
                    value={vehiculeData.marque}
                    setValue={(value) => {
                      setVehiculeData({ ...vehiculeData, marque: value });
                    }}
                  />
                </div>
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Modèle"}
                    placeholder={"Modèle"}
                    styled={"filled-input"}
                    value={vehiculeData.model}
                    setValue={(value) => {
                      setVehiculeData({ ...vehiculeData, model: value });
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Energie"}
                    placeholder={"Energie"}
                    styled={"filled-input"}
                    value={vehiculeData.energy}
                    setValue={(value) => {
                      setVehiculeData({ ...vehiculeData, energy: value });
                    }}
                  />
                </div>
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Puissance"}
                    placeholder={"Puissance"}
                    styled={"filled-input"}
                    value={vehiculeData.puissance}
                    setValue={(value) => {
                      setVehiculeData({ ...vehiculeData, puissance: value });
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Immatriculation"}
                    placeholder={"Immatriculation"}
                    styled={"filled-input"}
                    value={vehiculeData.car_plate}
                    setValue={(value) => {
                      setVehiculeData({ ...vehiculeData, car_plate: value });
                    }}
                  />
                </div>
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Date de mise en circulation"}
                    placeholder={"Date de mise en circulation"}
                    styled={"filled-input"}
                    value={vehiculeData.circulationDate}
                    setValue={(value) => {
                      setVehiculeData({
                        ...vehiculeData,
                        circulationDate: value,
                      });
                    }}
                  />
                </div>
              </div>
              <hr />
            </div>

            <span className="d-flex justify-content-end">
              <Button
                styled="primary"
                title="Enregistrer et continuer"
                onClick={() => {
                  handleSubmit();
                }}
              />
            </span>
          </div>
        </div> */}
      </div>
      <div className="coll-3">
        <HelpBox
          image={image}
          label="Besoin d’aide?"
          description="Vous pouvez contacter nos conseillers pour obtenir votre devis personnalisé"
          buttonText="Appeler"
          buttonIcon={<MdOutlinePhone />}
        />
      </div>
    </div>
  );
};

export default ProductInfo;
