import React, { Fragment, useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import { createOffer, createPricing, updateOffer } from "./services";
import { getAllEnabledProducts, getProductById } from "../products/services";
import Input from "../../components/ui/Input";
import DropdownSB from "../../components/ui/DropdownSB";
import CustomCheckbox from "../../components/ui/CustomCheckBox";
import DropdownMultiple from "../../components/ui/DropdownMuliple";
import CustomSwitch from "../../components/ui/CustomSwitch";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    padding: "0px",
    minHeight: "50%",
    overflow: "visible",
  },
};

const OfferModal = ({
  createOfferModalIsOpen,
  setCreateOfferModalIsOpen,
  setOfferAdded,
  ...props
}) => {
  const Tabs = ["Informations générales", "Configuration", "Tarification"];
  const [step, setStep] = useState("Informations générales");
  const [productList, setProductList] = useState([]);
  const [offerData, setOfferData] = useState({});
  const [pricingData, setPricingData] = useState({});
  const [createdOffer, setCreatedOffer] = useState({});

  useEffect(() => {
    getAllEnabledProducts()
      .then(({ data }) => {
        const Products = data
          .filter((el) => !!el.properties)
          .map((el) => {
            return { id: el.id, value: el.id, label: el.name };
          });
        setProductList(Products);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (!!props?.offerData) {
      const Product = productList.find(
        (el) => el.id === props.offerData.productId
      );
      setOfferData({ ...props.offerData, productId: Product });
    }
  }, [props?.offerData]);

  const handleCloseModal = () => {
    setCreateOfferModalIsOpen(false);
    setOfferData({});
    setPricingData({});
    setStep("Informations générales");
  };
  const handleSubmitOffer = () => {
    props?.offerData?.id
      ? updateOffer(offerData.id, {
          ...offerData,
          productId: offerData.productId.id,
        })
          .then((resp) => {
            setOfferAdded((prevState) => {
              return !prevState;
            });
          })
          .catch((e) => console.log(e))
      : createOffer({ ...offerData, productId: offerData.productId.id })
          .then((response) => {
            setCreatedOffer(response.data);
            setStep("Tarification");
            setOfferAdded((prevState) => {
              return !prevState;
            });
          })
          .catch((e) => console.log(e));
  };
  const handleSubmitPricing = () => {
    Object.entries(pricingData).map(([key, value], index) => {
      createPricing({ ...value, periodType: key, offerId: createdOffer })
        .then((resp) => {
          handleCloseModal();
          index === 0 &&
            updateOffer(createdOffer, { status: "ACTIVE" }).then(() =>
              setOfferAdded((prevState) => {
                return !prevState;
              })
            );
        })
        .catch((e) => console.log(e));
    });
  };
  return (
    <Modal isOpen={createOfferModalIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>{offerData?.id ? "Modifier l'offre" : "Créer une offre"}</h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCloseModal();
          }}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-3 h-100">
        <div className="d-flex nav-tabs justify-content-around">
          {Tabs.map((el, index) => {
            return (
              ((index !== 2 && !!offerData?.id) || !offerData.id) && (
                <span
                  key={el}
                  className={`tab-item ${step === el && "active-tab"}`}
                >
                  {index + 1 + ". " + el}
                </span>
              )
            );
          })}
        </div>
        <div className="mt-4">
          {step === "Informations générales" && (
            <GenerealInfo
              offerData={offerData}
              setOfferData={setOfferData}
              productList={productList}
              handleCloseModal={handleCloseModal}
              setStep={setStep}
              selectedProduct={!!props?.offerData?.productId}
            />
          )}
          {step === "Configuration" && (
            <Configuration
              offerData={offerData}
              setOfferData={setOfferData}
              handleCloseModal={handleCloseModal}
              handleSubmit={handleSubmitOffer}
              setStep={setStep}
            />
          )}
          {step === "Tarification" && (
            <Tarification
              pricingData={pricingData}
              setPricingData={setPricingData}
              handleSubmit={handleSubmitPricing}
              setStep={setStep}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OfferModal;

const GenerealInfo = ({
  offerData,
  setOfferData,
  productList,
  handleCloseModal,
  setStep,
  ...props
}) => {
  return (
    <div className="d-flex flex-column gap-3">
      <div style={{ fontSize: "21px" }}>Informations générales</div>
      <div className="d-flex gap-5">
        <Input
          style={{ width: "100%" }}
          label={"Nom de l'offre"}
          styled={"filled-input"}
          value={offerData.name}
          setValue={(value) => {
            setOfferData({ ...offerData, name: value });
          }}
        />

        <Input
          style={{ width: "100%" }}
          label={"Date de début"}
          styled={"filled-input"}
          value={offerData.startDate}
          setValue={(value) => {
            setOfferData({ ...offerData, startDate: value });
          }}
        />
      </div>
      <div className="d-flex gap-5">
        <DropdownSB
          style={{ width: "100%" }}
          label={"Produit"}
          placeholder={"Sélectionner un produit"}
          selectedValue={offerData.productId}
          searchEnabled={true}
          dataList={productList}
          onSelectItem={(el) => {
            setOfferData({ ...offerData, productId: el });
          }}
          disabled={!!props?.selectedProduct}
        />

        <Input
          style={{ width: "100%" }}
          label={"Date de fin"}
          styled={"filled-input"}
          value={offerData.endDate}
          setValue={(value) => {
            setOfferData({ ...offerData, endDate: value });
          }}
        />
      </div>
      <div className="d-flex justify-content-end gap-3 mt-auto">
        <Button
          title={"Annuler"}
          styled={"secondary"}
          onClick={() => handleCloseModal()}
        />
        <Button
          title={"Suivant"}
          styled={"primary"}
          onClick={() => {
            !!offerData?.productId && setStep("Configuration");
          }}
        />
      </div>
    </div>
  );
};

const Configuration = ({
  offerData,
  setOfferData,
  handleCloseModal,
  handleSubmit,
  setStep,
  ...props
}) => {
  const [productDetails, setProductDetails] = useState({});
  const [productProperties, setProductProperties] = useState([]);

  const handleChangeValue = (code, value, type) => {
    const Rules = productProperties?.map((el) => {
      if (el.code === code) {
        return { ...el, value: { ...el.value, [type]: value } };
      } else {
        return el;
      }
    });

    setProductProperties(Rules);
    const StringyfiedRules = Rules.map((el) => {
      return {
        ...el,
        value: JSON.stringify(el.value),
      };
    });
    setOfferData({ ...offerData, rules: StringyfiedRules });
  };

  useEffect(() => {
    if (!!offerData.productId?.id) {
      getProductById(offerData.productId?.id)
        .then(async (response) => {
          setProductDetails(response.data);

          if (!!offerData?.id) {
            const Properties = await response.data?.properties?.map((el) => {
              const rule = offerData.rules.find((e) => e.code === el.code);
              return {
                ...el,
                value: JSON.parse(rule.value),
                id: rule.id,
              };
            });
            const Rules1 = await Properties.filter((el) =>
              ["Date", "Number"].includes(el.propertyType)
            );
            const Rules2 = await Properties.filter(
              (el) => !["Date", "Number"].includes(el.propertyType)
            );
            setProductProperties([...Rules1, ...Rules2]);
            setOfferData({ ...offerData, rules: Properties });
          } else {
            //sort props for double inputs at the beginning
            const Rules1 = response.data?.properties.filter((el) =>
              ["Date", "Number"].includes(el.propertyType)
            );
            const Rules2 = response.data?.properties.filter(
              (el) => !["Date", "Number"].includes(el.propertyType)
            );
            setProductProperties([...Rules1, ...Rules2]);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [offerData.productId]);
  return (
    <div className="d-flex flex-column gap-3">
      <div style={{ fontSize: "18px" }}>
        Configuration de l'offre du produit:{" "}
        <span style={{ fontWeight: "bold", fontSize: "18px" }}>
          {productDetails.name}
        </span>
      </div>
      <div className="d-flex flex-wrap gap-3">
        {productProperties?.map((rule, index) => {
          let closedValues = [];
          let selectedValues = [];
          if (rule.propertyType === "List") {
            closedValues = rule.validationRules.closedValues.map(
              (el, index) => {
                return { id: index, code: el, label: el };
              }
            );

            //handle selected values
            if (!!rule?.value?.selectedValues) {
              selectedValues = rule.value.selectedValues.map((el, index) => {
                return { id: index, code: el, label: el };
              });
            }
          }
          return ["Date", "Number"].includes(rule.propertyType) ? (
            <Fragment key={index}>
              <div className="col-5 ms-3 me-1">
                <Input
                  label={rule.label}
                  placeholder={"Min"}
                  styled={"filled-input"}
                  value={rule.value?.minValue}
                  setValue={(value) => {
                    handleChangeValue(rule.code, value, "minValue");
                  }}
                />
              </div>
              <span className="align-self-end">-</span>
              <div className="col-5 mx-2">
                <Input
                  label={rule.label}
                  placeholder={"Max"}
                  styled={"filled-input"}
                  value={rule.value?.maxValue}
                  setValue={(value) => {
                    handleChangeValue(rule.code, value, "maxValue");
                  }}
                />
              </div>
            </Fragment>
          ) : (
            <div className="col-5 mx-3" key={index}>
              {rule.propertyType === "List" ? (
                <div className="d-flex flex-column">
                  <label>{rule.label}</label>
                  <DropdownMultiple
                    dataList={closedValues}
                    checkedItems={selectedValues}
                    placeholder="Sélectionner un élément"
                    onSelectItems={(selectedProperties) => {
                      const SelectedItems = selectedProperties.map((el) => {
                        return el.code;
                      });
                      handleChangeValue(
                        rule.code,
                        SelectedItems,
                        "selectedValues"
                      );
                    }}
                  />
                </div>
              ) : rule.propertyType === "Boolean" ? (
                <div className="d-flex flex-column gap-2">
                  <label>{rule.label}</label>
                  <CustomSwitch
                    state={rule.value?.value}
                    onToggleState={() => {
                      handleChangeValue(rule.code, !rule.value?.value, "value");
                    }}
                  />
                </div>
              ) : (
                <Input
                  label={rule.label}
                  styled={"filled-input"}
                  value={rule.value?.value}
                  setValue={(value) => {
                    handleChangeValue(rule.code, value, "value");
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-end gap-3 mt-auto">
        <Button
          title={"Précédent"}
          styled={"secondary"}
          onClick={() => setStep("Informations générales")}
        />
        <Button
          title={offerData?.id ? "Mettre à jour" : "Suivant"}
          styled={"primary"}
          onClick={() => {
            handleSubmit();
            offerData?.id && handleCloseModal();
          }}
        />
      </div>
    </div>
  );
};

const Tarification = ({
  pricingData,
  setPricingData,
  handleSubmit,
  ...props
}) => {
  const handlePricings = (key, value) => {
    setPricingData({
      ...pricingData,
      [key]: { ...pricingData[key], price: value },
    });
  };
  return (
    <div className="d-flex flex-column gap-4">
      <div
        className="d-flex justify-content-between align-items-center w-75"
        style={{ minHeight: "40px" }}
      >
        <div className="d-flex gap-2 align-items-center">
          <CustomCheckbox
            onCheckOn={() => {
              setPricingData({
                ...pricingData,
                ONESHOT: { price: "", currency: "EUR" },
              });
            }}
            onCheckOff={() => {
              const Prices = { ...pricingData };
              delete Prices.ONESHOT;
              setPricingData(Prices);
            }}
          />
          <span>Paiement comptant</span>
        </div>

        {!!pricingData?.ONESHOT && (
          <Input
            styled={"filled-input"}
            type={"number"}
            value={pricingData?.ONESHOT.price}
            setValue={(value) => {
              handlePricings("ONESHOT", value);
            }}
            icon={"€"}
          />
        )}
      </div>

      <div
        className="d-flex justify-content-between align-items-center w-75"
        style={{ minHeight: "40px" }}
      >
        <div className="d-flex gap-2 align-items-center">
          <CustomCheckbox
            onCheckOn={() => {
              setPricingData({
                ...pricingData,
                MONTH: { price: "", currency: "EUR" },
              });
            }}
            onCheckOff={() => {
              const Prices = { ...pricingData };
              delete Prices.MONTH;
              setPricingData(Prices);
            }}
          />
          <span>Paiement mensuel</span>
        </div>

        {!!pricingData?.MONTH && (
          <Input
            styled={"filled-input"}
            type={"number"}
            value={pricingData.price}
            setValue={(value) => {
              handlePricings("MONTH", value);
            }}
            icon={"€"}
          />
        )}
      </div>
      <div
        className="d-flex justify-content-between align-items-center w-75"
        style={{ minHeight: "40px" }}
      >
        <div className="d-flex gap-2 align-items-center">
          <CustomCheckbox
            onCheckOn={() => {
              setPricingData({
                ...pricingData,
                YEAR: { price: "", currency: "EUR" },
              });
            }}
            onCheckOff={() => {
              const Prices = { ...pricingData };
              delete Prices.YEAR;
              setPricingData(Prices);
            }}
          />
          <span>Paiement annuel</span>
        </div>

        {!!pricingData?.YEAR && (
          <Input
            style={{}}
            styled={"filled-input"}
            type={"number"}
            value={pricingData.price}
            setValue={(value) => {
              handlePricings("YEAR", value);
            }}
            icon={"€"}
          />
        )}
      </div>

      <div className="d-flex justify-content-end gap-3 mt-auto">
        <Button title={"Annuler"} styled={"secondary"} onClick={() => {}} />
        <Button
          title={"Créer un tarif"}
          styled={"primary"}
          onClick={() => {
            handleSubmit();
          }}
        />
      </div>
    </div>
  );
};
