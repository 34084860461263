import React, { useState, useEffect } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import PropTypes from "prop-types";

const DropdownSB = ({
  dataList,
  onSelectItem,
  placeholder,
  disabled,
  searchEnabled,
  selectedValue,
  ...props
}) => {
  const [showItems, setShowItems] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setFilteredData(
      dataList.filter((el) =>
        el.label
          .toLocaleLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(search.toLocaleLowerCase())
      )
    );
  }, [dataList, search]);
  useEffect(() => {
    if (dataList.length === 1) {
      {
        onSelectItem(dataList[0]);
        setSelectedItem(dataList[0]);
      }
    }
  }, [dataList]);
  useEffect(() => {
    if (selectedValue?.id !== undefined) {
      onSelectItem(selectedValue);
      setSelectedItem(selectedValue);
    }
  }, [selectedValue]);
  return (
    <>
      <div
        className="d-inline-flex flex-column"
        style={{ position: "relative", ...props?.style }}
      >
        {props.label && <label>{props.label}</label>}
        <div className="searcharea">
          {searchEnabled ? (
            <input
              className="dropdown-search-input"
              type="text"
              placeholder={
                !!selectedItem?.value ? selectedItem.label : placeholder
              }
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              disabled={!showItems}
            ></input>
          ) : (
            <span>
              {!!selectedItem?.value ? selectedItem.label : placeholder}
            </span>
          )}
          {showItems ? (
            <FiChevronUp onClick={() => setShowItems(false)} />
          ) : (
            <FiChevronDown onClick={() => !!!disabled && setShowItems(true)} />
          )}
        </div>
        {showItems && (
          <div className="itemss">
            {filteredData.map((el) => (
              <span
                key={el.id}
                className="item"
                onClick={() => {
                  setSelectedItem(el);
                  onSelectItem?.(el);
                  setShowItems(false);
                  setSearch("");
                }}
              >
                {el.label}
              </span>
            ))}
          </div>
        )}
        {props.errorMsg && (
          <span style={{ color: "red", fontSize: "12px" }}>
            {props.errorMsg}
          </span>
        )}
      </div>
    </>
  );
};

export default DropdownSB;
