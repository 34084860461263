import "./App.css";
import Keycloak from "keycloak-js";
import AppContainer from "./AppContainer/main";
import GetKeycloakInstance from "./utils/keycloak";
import { useEffect, useState } from "react";

function App() {
  const keycloak_auth = process.env.REACT_APP_AUTH === "keycloak";

  const [keycloak, setKeycloak] = useState<Keycloak | null>(null);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (keycloak_auth)
      GetKeycloakInstance()
        .then((data) => {
          console.log("authenticated", data.authenticated);
          setAuthenticated(data.authenticated?.valueOf ? true : false);

          setKeycloak(data);
          console.log("App Keycloak", JSON.stringify(keycloak));

          data.onTokenExpired = () => {
            console.log("token expired");
          };
        })
        .catch((error) => {
          console.error(error);
        });
  }, [keycloak]);

  if (keycloak_auth) {
    if (!keycloak) {
      return (
        <>
          <div className="my-12">Initializing Keycloak ...</div>
        </>
      );
    }

    if (authenticated === true)
      return (
        <>
          <AppContainer />
        </>
      );
    else
      return (
        <>
          <div className="my-12">Unable to initiate auth!</div>
        </>
      );
  } else {
    return (
      <>
        <AppContainer />
      </>
    );
  }
}

export default App;
