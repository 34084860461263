import { AxiosHeaders } from "axios";
import { config, keycloakAxios } from "../config/config";


export const iosisLogout = () => {

    window.dispatchEvent(new Event("logout"));
    
}


export const keycloakLogout = () => {

    const payload = {
        client_id: "iosis",
        //id_token_hint: localStorage.getItem('access_token'),
        //access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token')
    }

    let headers = new AxiosHeaders();
    headers.setContentType('application/x-www-form-urlencoded');

    keycloakAxios.post(config.KEYCLOAK_BASE_URL + '/realms/master/protocol/openid-connect/logout', payload, {
        headers: headers,
        withCredentials: true
    })
        .then((response: { status: number; }) => {
            console.log('Keycloak logout response', JSON.stringify(response))

            if (response.status === 200 || response.status === 204) {

                window.dispatchEvent(new Event("logout"));

                //props.showError(null)
            }
            else {
                //props.showError("response.status : " + response.status);
            }

        })
        .catch((error: any) => {
            console.log(error);
        });

}