import React, { useEffect, useState } from "react";
import {
  Header,
  Button,
  Table,
  Status,
  SidePanel,
  Snackbar
} from "iosis-storybook/dist/bundle";
import { GoStar } from "react-icons/go";
import { FaPlus } from "react-icons/fa6";
import AddPropertyContent from "./AddPropertyContent";
const Properties = () => {
  const [propertyList, setPropertyList] = useState([
    {
      id: "1234",
      name: "Statut",
      code: "Status",
      entity: ["Personnes", "Contrats"],
      type: "Chaîne de caractère",
      condition: "Min: 3 - Max: 15"
    },
    {
      id: "1235",
      name: "Statut",
      code: "Status",
      entity: ["Personnes", "Contrats"],
      type: "Chaîne de caractère",
      condition: "Min: 3 - Max: 15"
    }
  ]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [propertyShown, setPropertyShown] = useState({
    add: false,
    detail: false
  });
  const [propertyArchived, setPropertyArchived] = useState(false);
  const [propertyLabel, setPropertyLabel] = useState("");
  const [propertyCode, setPropertyCode] = useState("");
  const columns = [
    {
      key: "id",
      label: "Référence"
    },

    {
      key: "name",
      label: "Label"
    },
    {
      key: "code",
      label: "code"
    },
    {
      key: "entity",
      label: "Entité",
      getter: (entities) => entities.join(" ; ")
    },
    {
      key: "type",
      label: "type"
    },
    {
      key: "condition",
      label: "condition"
    },
    {
      key: "id",
      label: "Actions",
      getter: (id) => (
        <Button
          styled={"tertiary"}
          label='Modifier'
          size={"sm"}
          onClick={(event) => {
            event.stopPropagation();
            const selectedPoperty = propertyList.find(
              (product) => product.id === id
            );
            if (selectedPoperty) setSelectedProperty(selectedProperty);
            setPropertyShown(() => ({
              detail: true,
              add: false
            }));
          }}
        />
      )
    }
  ];
  const onArchive = (itemsId) => {
    setPropertyList((prevList) =>
      prevList.filter((item) => !itemsId.includes(item.id))
    );
    setPropertyArchived(true);
  };
  return (
    <>
      <div className='property-list-container'>
        {propertyArchived && (
          <Snackbar
            type='success'
            text='La propriété a été archivée avec succès'
            size='small'
            withLink={true}
            textLink='Annuler'
            autoClose={8000}
          />
        )}
        <Header
          title={"Propriétés"}
          subtitle={
            "Créez ici les propriétés qui figureront dans les fiches des personnes, des entreprises et des contrats"
          }
          titleIcon={<GoStar color='#62AF98' />}
          primaryButton={
            propertyList.length !== 0 && (
              <Button
                styled='primary'
                label='Créer une propriété'
                startIcon={<FaPlus />}
                onClick={() => {
                  setPropertyShown((prevState) => ({
                    ...prevState,
                    add: true
                  }));
                }}
                size={"md"}
              />
            )
          }
        />
        <div className='container'>
          <Table
            pageSize={10}
            columns={columns}
            data={propertyList}
            entriesCount={propertyList.length}
            onRowClick={(property) => {
              setPropertyShown((prevState) => ({
                ...prevState,
                detail: true,
                add: false
              }));
              setSelectedProperty(property);
            }}
            pageSizes={[10, 20, 50]}
            setPageSize={() => {}}
            emptyPageTitle={"Il n’y a aucune propriété créée sur la plateforme"}
            emptyPageDesc={"Cliquez sur “Créer une propriété” pour commencer"}
            onClickEmptyPage={() => {
              setPropertyShown((prevState) => ({
                ...prevState,
                add: true
              }));
            }}
            emptyPageTextButton={"Créer une propriété"}
            withSelect={true}
            onArchive={(items) => {
              onArchive(items);
            }}
          />
        </div>
        {propertyShown.add && (
          <SidePanel
            title='Créer une propriété'
            onClose={() => {
              setPropertyShown((prevState) => ({
                ...prevState,
                add: false
              }));
            }}
            content={
              <AddPropertyContent
                setPropertyCode={setPropertyCode}
                setPropertyLabel={setPropertyLabel}
              />
            }
            primaryButton={
              <Button
                styled={"primary"}
                label={"Créer"}
                size={"md"}
                startIcon={<FaPlus />}
                onClick={() => {
                  //fill with the function
                }}
              />
            }
            secondaryButton={
              <Button
                styled={"tertiary"}
                label={"Annuler"}
                size={"md"}
                startIcon={<FaPlus />}
                onClick={() => {
                  setPropertyShown((prevState) => ({
                    ...prevState,
                    add: false
                  }));
                }}
              />
            }
          />
        )}
      </div>
    </>
  );
};

export default Properties;
