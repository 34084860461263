import React, { Fragment, useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import Button from "../../../../components/ui/Button";
import {
  getAllPricingByOffer,
  getOffersByProduct,
} from "../../../offers/services";
import DropdownSB from "../../../../components/ui/DropdownSB";
import { updateQuote } from "../services";
import { config } from "../../../../config/config";
import image from "../../../../img/iosis-logo.svg";
import { MdOutlinePhone } from "react-icons/md";
import { BiCalendarAlt } from "react-icons/bi";

import {
  HelpBox,
  Input as Inputt,
  RadioButton,
} from "iosis-storybook/dist/bundle";

const Offers = ({ setStep, quoteDetails, setQuoteUpdated, ...props }) => {
  const PricingType = {
    ONESHOT: "Comptant",
    MONTH: "Mensuel",
    YEAR: "Annuel",
  };
  const PriceLabels = {
    ONESHOT: "Comptant",
    MONTH: "/Mois",
    YEAR: "/An",
  };
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [pricings, setPricings] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState({});

  useEffect(() => {
    getOffersByProduct(config.vehicule_product_id)
      .then((res) => {
        setOffers(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (offers.length > 0) {
      //Search for Elligible offer
      const Offer = offers.filter((offer) => {
        let elligible = true;
        for (let i = 0; i < offer.rules.length; i++) {
          const rule = offer.rules[i];
          const RuleValue = JSON.parse(rule.value);
          if (rule.propertyType === "Number") {
            if (
              Number(quoteDetails.productData[rule.code]) <
                Number(RuleValue.minValue) ||
              Number(quoteDetails.productData[rule.code]) >
                Number(RuleValue.maxValue)
            ) {
              elligible = false;
              break;
            }
          }
          if (rule.propertyType === "Date") {
            const minDate = new Date(RuleValue.minValue);
            const maxDate = new Date(RuleValue.maxValue);
            const targetDate = new Date(quoteDetails.productData[rule.code]);
            if (targetDate < minDate || targetDate > maxDate) {
              elligible = false;
              break;
            }
          }
          if (rule.propertyType === "Boolean") {
            if (RuleValue.value != quoteDetails.productData[rule.code]) {
              elligible = false;
              break;
            }
          }
          if (rule.propertyType === "List") {
            if (
              !RuleValue.selectedValues.includes(
                quoteDetails.productData[rule.code]
              )
            ) {
              elligible = false;
              break;
            }
          }
        }
        return elligible;
      });
      setSelectedOffer(Offer[0]);
      console.log(Offer);
    }
  }, [offers, quoteDetails.productData]);

  useEffect(() => {
    if (!!selectedOffer?.id) {
      getAllPricingByOffer(selectedOffer.id)
        .then((res) => {
          const Prices = res.data.map((el) => {
            return {
              value: el.id,
              label: PricingType[el.periodType],
              ...el,
            };
          });
          setPricings(Prices);
          setSelectedPrice(Prices[0]);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedOffer]);

  const handleSubmit = () => {
    const payload = { offerId: selectedOffer.id, priceId: selectedPrice.id };
    updateQuote(quoteDetails.id, payload)
      .then((res) => {
        setStep(3);
        setQuoteUpdated((prev) => {
          return !prev;
        });
      })
      .catch((e) => console.log(e));
  };
  //console.log(pricings, selectedPrice);
  return (
    <div className="roww gapp-6">
      <div className="coll-9">
        <div className="roww align-center">
          <FaArrowLeft
            size={16}
            color="#3D7D65"
            className="mr-3"
            onClick={() => {
              setStep(2);
            }}
          />

          <span className="text-md-medium">Étape 3: Offres</span>
        </div>
        <hr></hr>
        <div className="offers-info-container">
          <div className="roww text-sm-semibold">Devis numéro:</div>
          <div className="roww gapp-5 justify-space-between align-flex-end">
            <div className="coll-5">
              <Inputt
                label="Période de couverture"
                placeholder="2/12/1988"
                iconLeft={<BiCalendarAlt />}
              />
            </div>
            <span className="coll-1 mb-2 period-seperator">-</span>
            <div className="coll-5">
              <Inputt placeholder="2/12/1988" iconLeft={<BiCalendarAlt />} />
            </div>
          </div>

          {/* list offers */}
          <div className="offer-proposal-container">
            <div className="coll-6 columnn">
              <span className="color-neutral-700 text-sm-semibold">{`Offre ${selectedOffer.name}`}</span>
              <div className="roww gapp-2 align-center">
                <span className="color-primary-700 h3-header-bold">
                  {selectedPrice.price + " €"}
                </span>
                <span className="color-neutral-400 text-sm-medium">
                  {PriceLabels[selectedPrice.periodType]}
                </span>
              </div>
              <div className="roww gapp-3">
                {pricings.map((price, index) => (
                  <Fragment key={index}>
                    <RadioButton
                      label={`Tarif ${price.label}`}
                      checked={price.id === selectedPrice.id}
                      onChange={() => {
                        console.log("changed", selectedPrice);
                        setSelectedPrice(price);
                      }}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
            <div className="roww coll-6 gapp-4">
              {selectedOffer.rules?.map((rule) => {
                let value = "";
                const obj = JSON.parse(rule.value);
                if (
                  rule.propertyType === "Number" ||
                  rule.propertyType === "Date"
                ) {
                  value = `Entre ${obj.minValue} et ${obj.maxValue}`;
                }
                if (
                  rule.propertyType === "String" ||
                  rule.propertyType === "Boolean"
                ) {
                  value = obj.value;
                }
                if (rule.propertyType === "List") {
                  value = obj.selectedValues.join(" - ");
                }
                return (
                  <div className="columnn">
                    <span className="text-md-semibold color-neutral-700">
                      {rule.label}
                    </span>
                    <span>{value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="col-8 d-flex flex-column gap-5">
          {!!!selectedOffer?.id ? (
            <div>Aucune offre disponible pour le moment</div>
          ) : (
            <div className="d-flex flex-column gap-3">
              <div className="d-flex justify-content-between">
                <div>
                  <span style={{ color: "#009c6a" }}>Devis numéro : </span>
                  <span>{quoteDetails.id.slice(0, 8)}</span>
                </div>
                <span>
                  <DropdownSB
                    style={{ width: "100%" }}
                    selectedValue={pricings[0]}
                    dataList={pricings}
                    onSelectItem={(el) => {
                      setSelectedPrice(el);
                    }}
                  />
                </span>
              </div>
              <div className="offer-card-container">
                <div className="offer-box col-3">
                  <span>{`Offre ${selectedOffer.name}`}</span>
                  <span className="d-flex gap-3 justify-content-center align-items-center">
                    <span style={{ color: "#3d7d65", fontSize: "36px" }}>
                      {selectedPrice.price + " €"}
                    </span>
                    <span style={{ color: "#777777" }}>
                      {PriceLabels[selectedPrice.periodType]}
                    </span>
                  </span>
                </div>
                <div className="col-8 d-flex gap-4 flex-wrap">
                  {selectedOffer.rules.map((rule) => {
                    let value = "";
                    const obj = JSON.parse(rule.value);
                    if (
                      rule.propertyType === "Number" ||
                      rule.propertyType === "Date"
                    ) {
                      value = `Entre ${obj.minValue} et ${obj.maxValue}`;
                    }
                    if (
                      rule.propertyType === "String" ||
                      rule.propertyType === "Boolean"
                    ) {
                      value = obj.value;
                    }
                    if (rule.propertyType === "List") {
                      value = obj.selectedValues.join(" - ");
                    }
                    return (
                      <div className="d-flex flex-column col-3">
                        <span className="title-element">{rule.label}</span>
                        <span>{value}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <span className="d-flex justify-content-end">
            {!!!selectedOffer?.id ? (
              <Button
                styled="primary"
                title="Retourner"
                onClick={() => {
                  setStep(1);
                }}
              />
            ) : (
              <Button
                styled="primary"
                title="Procéder au paiement"
                onClick={() => {
                  handleSubmit();
                }}
              />
            )}
          </span>
        </div> */}
      </div>
      <div className="coll-3">
        <HelpBox
          image={image}
          label="Besoin d’aide?"
          description="Vous pouvez contacter nos conseillers pour obtenir votre devis personnalisé"
          buttonText="Appeler"
          buttonIcon={<MdOutlinePhone />}
        />
      </div>
    </div>
  );
};

export default Offers;
