import React, { useEffect, useState } from "react";
import { getAllContracts } from "./services";
import AddContractModal from "./AddContractModal";
import { formatDate } from "../../utils/utilities";
import {
  Header,
  Button,
  Table,
  Status,
  SidePanel,
} from "iosis-storybook/dist/bundle";
import { IoStarOutline } from "react-icons/io5";
import ContractDetailsContent from "./ContractDetailsContent";

const Contracts = () => {
  const [contratList, setContratsList] = useState([]);
  const [addContractModalIsOpen, setAddContractModalIsOpen] = useState(false);
  const [contractAdded, setContractAdded] = useState(false);
  const [selectedContract, setSelectedContract] = useState({});

  const StatusList = {
    VALID: { type: "draft", title: "En cours" },
    DRAFT: { type: "danger", title: "Résilié" },
    RESIGNED: { type: "danger", title: "Résilié" },
  };
  const columns = [
    {
      key: "id",
      label: "N° du contrat",
      getter: (value) => `${value.slice(0, 8)}`,
    },
    {
      key: "type",
      label: "Type",
    },
    {
      key: "company",
      label: "Entreprise",
      getter: () => "Assurance",
    },
    {
      key: "person",
      label: "Souscripteur",
      getter: () => "Automobile",
    },
    {
      key: "startDate",
      label: "Date de début",
      getter: () => "10/12/2024",
    },
    {
      key: "endDate",
      label: "Date de fin",
      getter: () => "10/12/2024",
    },
    {
      key: "status",
      label: "Statut",
      getter: (value) => (
        <Status
          type={StatusList[value].type}
          title={StatusList[value].title}
          forTable={true}
        />
      ),
    },
    {
      key: "creationDate",
      label: "Créé le",
      getter: (value) => formatDate(value),
    },
    {
      key: "status",
      label: "Action",
      getter: (value) => (
        <Button
          styled={"tertiary"}
          label="Résilier"
          onClick={(e) => e.stopPropagation()}
          disabled={value === "DRAFT"}
        />
      ),
    },
  ];

  useEffect(() => {
    getAllContracts()
      .then((res) => {
        setContratsList(res.data);
      })
      .catch((e) => console.log(e));
  }, [contractAdded]);
  return (
    <div className="contract-page-container">
      <Header
        title="Contrats"
        subtitle="Consultez ici la liste des contrats et ajoutez des rôles"
        titleIcon={<IoStarOutline color="#62AF98" />}
      />
      {!!selectedContract?.id && (
        <SidePanel
          category="Contrats"
          title={selectedContract?.id?.slice(0, 8)}
          onClose={() => setSelectedContract({})}
          content={
            <ContractDetailsContent selectedContract={selectedContract} />
          }
        />
      )}
      <div className="container">
        <div>
          <Table
            pageSize={10}
            setPageSize={() => {}}
            currentPage={1}
            setCurrentPage={() => {}}
            pageSizes={[10, 20, 50]}
            entriesCount={contratList.length}
            columns={columns}
            data={contratList}
            onRowClick={(contract) => {
              setSelectedContract(contract);
            }}
            emptyPageTitle={"Il n’y a aucun contrat ajouté dans la plateforme"}
          />
        </div>
      </div>
    </div>
  );
};

export default Contracts;
