import { useEffect, useState } from "react";
import {
  Status,
  Button,
  Select,
  Accordeon,
  SwitchButton,
  InputWithActions
} from "iosis-storybook/dist/bundle";
import { formatDate } from "../../utils/utilities";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import { CgMoreVerticalAlt } from "react-icons/cg";
import { MdModeEditOutline } from "react-icons/md";
import emtpyList from "../../img/empty-list.svg";
import {
  addPropertyToContract,
  getAllContractProperties,
  getContractDetails,
  updateContractProperty
} from "./services";
import { IoMdAdd } from "react-icons/io";
const ContractDetailsContent = ({ selectedContract, ...props }) => {
  const [contractDetails, setContractDetails] = useState({});
  const [restProperties, setRestProperties] = useState([]);
  const [contractProperties, setContractProperties] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [contractUpdated, setContractUpdated] = useState(false);
  const [error, setError] = useState({});
  const [showAddRole, setShowAddRole] = useState(false);
  const [addRolePersonType, setAddRolePersonType] = useState("Entreprise");

  const StatusList = {
    VALID: { type: "draft", title: "En cours" },
    DRAFT: { type: "danger", title: "Résilié" },
    RESIGNED: { type: "danger", title: "Résilié" }
  };

  useEffect(() => {
    console.log(addRolePersonType);
  }, [addRolePersonType]);

  useEffect(() => {
    getContractDetails(selectedContract.id)
      .then((response) => {
        setContractDetails(response.data);
      })
      .catch((e) => console.log(e));
  }, [selectedContract.id, contractUpdated]);

  const handleAddProperties = (key, prop, val) => {
    const properties = restProperties.map((el) => {
      if (el.key === key) {
        return { ...el, [prop]: val };
      } else {
        return el;
      }
    });
    setRestProperties(properties);
  };

  const handleEditProperties = (key, prop, val) => {
    const properties = contractProperties.map((el) => {
      if (el.key === key) {
        return { ...el, [prop]: val };
      } else {
        return el;
      }
    });
    setContractProperties(properties);
  };

  const getContractProperties = () => {
    let contractprops = [];
    if (!!contractDetails.additionalData) {
      contractprops = Object.entries(contractDetails.additionalData).map(
        ([key, value]) => {
          return {
            key: key,
            label: allProperties.find((el) => el.code === key).label,
            isEdit: false,
            value: value,
            type: typeof value,
            closedValues: Array.isArray(value)
              ? allProperties.find((el) => el.code === key).validationRules
                  .closedValues
              : null
          };
        }
      );
    }
    return setContractProperties(contractprops.sort((a, b) => a.key < b.key));
  };

  const getMissingProperties = () => {
    getAllContractProperties()
      .then((res) => {
        let missingprops = res.data.map((el) => {
          return {
            key: el.code,
            label: el.label,
            isAdd: false,
            value: "",
            type: el.propertyType,
            closedValues: el?.validationRules?.closedValues || null
          };
        });
        setAllProperties(res.data);
        if (!!contractDetails.additionalData) {
          missingprops = missingprops.filter(
            (el) =>
              !Object.keys(contractDetails.additionalData).includes(el.key)
          );
        }
        return setRestProperties(missingprops.sort((a, b) => a.key < b.key));
      })
      .catch((e) => console.log(e));
  };

  const handleSubmitAddProperties = (key) => {
    const data = {
      data: {
        [key]: restProperties.find((el) => el.key === key).value
      }
    };
    addPropertyToContract(contractDetails.id, data)
      .then(() => {
        setContractUpdated((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => {
        setError({ ...error, [key]: e.response.data });
      });
  };

  const handleSubmitEditProperties = (key) => {
    const data = {
      data: {
        [key]: contractProperties.find((el) => el.key === key).value
      }
    };
    updateContractProperty(contractDetails.id, data)
      .then(() => {
        setContractUpdated((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => {
        setError({ ...error, [key]: e.response.data });
      });
  };

  useEffect(() => {
    getMissingProperties();
    getContractProperties();
  }, [contractDetails]);

  console.log(contractProperties);

  const ContractInfoContent = (
    <div className='columnn gapp-2'>
      <div className='columnn'>
        <span className='text-xs-bold color-neutral-700'>Créé le</span>
        <span className='text-sm-regular color-neutral-600'>
          {formatDate(selectedContract.creationDate)}
        </span>
      </div>
      {contractProperties.map(
        ({ key, label, isEdit, value, type, closedValues }) => (
          <InputWithActions
            value={value}
            label={label}
            button={<MdModeEditOutline />}
            mainEditingButton={<IoCheckmarkOutline size={10} />}
            onClickMain={() => {
              handleSubmitEditProperties(key);
            }}
            secondEditingButton={<IoCloseOutline size={10} />}
            onClickSecond={() => {
              handleEditProperties(key, "isEdit", false);
            }}
            onChange={(val) => {
              handleEditProperties(key, "value", val);
            }}
            typing={isEdit}
            //errorMsg={error[key]}
          />
        )
      )}
      {restProperties.map(
        ({ key, label, isAdd, value, type, closedValues }) => (
          <div className='columnn'>
            {!!isAdd ? (
              <InputWithActions
                value={value}
                label={label}
                button={<MdModeEditOutline />}
                mainEditingButton={<IoCheckmarkOutline size={10} />}
                onClickMain={() => {
                  handleSubmitAddProperties(key);
                }}
                secondEditingButton={<IoCloseOutline size={10} />}
                onClickSecond={() => {
                  handleAddProperties(key, "isAdd", false);
                }}
                onChange={(val) => {
                  handleAddProperties(key, "value", val);
                }}
                typing={isAdd}
                //errorMsg={error[key]}
              />
            ) : (
              <>
                <span className='text-xs-bold color-neutral-700'>{label}</span>
                <span>
                  <Button
                    styled='tertiary'
                    label='Ajouter'
                    size='sm'
                    onClick={() => {
                      handleAddProperties(key, "isAdd", true);
                    }}
                    endIcon={<IoMdAdd size={22} />}
                  />
                </span>
              </>
            )}
          </div>
        )
      )}
    </div>
  );

  const offers = [
    {
      name: "Basic",
      type: "Paiement comptant",
      price: "200 € "
    },
    {
      name: "Premium",
      type: "Paiement mensuelle",
      price: "80 € "
    }
  ];
  const OfferContent = (
    <div className='columnn'>
      {offers.length ? (
        offers.map((el, key) => (
          <div className='roww align-center'>
            <span className='coll-4 label-offer'>{el.name}</span>
            <div className='coll-8 columnn gapp-1'>
              <div className='columnn'>
                <span className='text-xs-bold color-neutral-600'>Type</span>
                <span className='text-sm-regular color-neutral-1000'>
                  {el.type}
                </span>
              </div>
              <div className='columnn'>
                <span className='text-xs-bold color-neutral-600'>Montant</span>
                <span className='text-sm-regular color-neutral-1000'>
                  {el.price}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='columnn align-center gapp-3'>
          <img src={emtpyList} alt='Empty list' />

          <span className='text-xs-medium color-neutral-700'>
            Il n’y a actuellement aucune offre
          </span>
        </div>
      )}
    </div>
  );

  const roles = [
    {
      name: "COMAR",
      role: "Assureur",
      date: "12 Janvier 2024"
    },
    {
      name: "Amine Rezgui",
      role: "Assuré",
      date: "12 Janvier 2024"
    }
  ];
  const RoleContent = (
    <div className='columnn'>
      {roles.length ? (
        roles.map((el, key) => (
          <div className='roww align-center'>
            <span className='coll-4 label-offer'>{el.name}</span>
            <div className='coll-8 columnn gapp-1'>
              <div className='columnn'>
                <span className='text-xs-bold color-neutral-600'>
                  Type de relation
                </span>
                <span className='text-sm-regular color-neutral-1000'>
                  {el.role}
                </span>
              </div>
              <div className='columnn'>
                <span className='text-xs-bold color-neutral-600'>
                  Date de création de rôle
                </span>
                <span className='text-sm-regular color-neutral-1000'>
                  {el.date}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='columnn align-center gapp-3'>
          <img src={emtpyList} alt='Empty list' />

          <span className='text-xs-medium color-neutral-700'>
            Il n’y a actuellement aucun role
          </span>
        </div>
      )}
    </div>
  );
  const createRoleContent = (
    <div className='columnn gapp-2'>
      <div className='coll-6'>
        <SwitchButton
          label1='Entreprise'
          label2='Personne'
          onSwitch={(value) => {
            setAddRolePersonType(value);
          }}
        />
      </div>
      <Select
        label='Type de relation'
        placeholder='Aucun rôle sélectionné'
        dataList={[
          { id: 1, label: "Assureur", value: "insurer" },
          { id: 2, label: "Assuré", value: "insured" }
        ]}
        onSelectItem={() => {}}
      />
      <Select
        label='Avec'
        placeholder='Choisir parmi la liste'
        dataList={
          addRolePersonType === "Personne"
            ? [
                { id: 1, label: "Amine Rezgui", value: "Amine Rezgui" },
                { id: 2, label: "John Doe", value: "John Doe" },
                { id: 1, label: "Amine Rezgui", value: "Amine Rezgui" },
                { id: 2, label: "John Doe", value: "John Doe" },
                { id: 1, label: "Amine Rezgui", value: "Amine Rezgui" },
                { id: 2, label: "John Doe", value: "John Doe" },
                { id: 1, label: "Amine Rezgui", value: "Amine Rezgui" },
                { id: 2, label: "John Doe", value: "John Doe" }
              ]
            : [
                { id: 1, label: "Comar", value: "comar" },
                { id: 2, label: "SlashUp ", value: "slashup" }
              ]
        }
        onSelectItem={() => {}}
      />
      <div className='roww gapp-2'>
        <span>
          <Button
            label='Ajouter un rôle'
            styled='primary'
            size='md'
            endIcon={<IoMdAdd size={22} />}
            onClick={() => {
              setShowAddRole(false);
            }}
          />
        </span>
        <span>
          <Button
            label='Annuler'
            styled='tertiary'
            size='md'
            onClick={() => {
              setShowAddRole(false);
            }}
          />
        </span>
      </div>
    </div>
  );
  return (
    <div className='columnn'>
      <div className='roww'>
        <span className='text-sm-semibold color-neutral-600'>
          Réference:&nbsp;
        </span>
        <span className='text-sm-regular color-neutral-1000'>
          {selectedContract.id.slice(0, 8)}
        </span>
      </div>
      <div className='roww align-center gapp-2'>
        <span className='text-lg-medium color-neutral-800'>
          {selectedContract.type}
        </span>
        <span>
          <Status
            type={StatusList[selectedContract.status].type}
            title={StatusList[selectedContract.status].title}
            //forTable={true}
            withIcon={false}
          />
        </span>
      </div>
      <div className='divider' />
      <Accordeon
        title='Informations'
        isChild
        children={ContractInfoContent}
        action={
          <Button size={"sm"} styled='icon' icon={<CgMoreVerticalAlt />} />
        }
      />
      <div className='divider' />
      <Accordeon title='Offres' isChild children={OfferContent} />
      <div className='divider' />
      <Accordeon
        title={`Rôles (${2})`}
        isChild
        children={RoleContent}
        action={
          <Button
            label='Ajouter'
            styled='tertiary'
            size='sm'
            startIcon={<IoMdAdd size={22} />}
            onClick={() => {
              setShowAddRole(true);
            }}
          />
        }
      />
      {showAddRole && createRoleContent}
    </div>
  );
};

export default ContractDetailsContent;
