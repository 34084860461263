import React, { useEffect, useState } from "react";
// import "./companies.scss";
import {
  Header,
  Table,
  Snackbar,
  SidePanel,
  Input,
  Button,
  Accordeon,
  InputWithActions
} from "iosis-storybook/dist/bundle";
import { IoMdMail } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import { GoStar } from "react-icons/go";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import { getCurrentDay } from "../../utils/functions";
import { ReactComponent as Link } from "../../img/icons/link.svg";
import Relations from "../personne/Relations";
import { CgMoreVerticalAlt } from "react-icons/cg";

const Companies = () => {
  const [openSidePanelAddEntreprise, setOpenedSidePanelAddEntreprise] =
    useState(false);
  const [openSidePanelEditEntreprise, setOpenedSidePanelEditentreprise] =
    useState(false);
  const [entreprises, setEntreprises] = useState([
    {
      ref: "1234567890",
      title: "Assure",
      name: "Jane",
      email: "janedoe@gmail.com",
      createdAt: "12 Décembre 2024",
      relations: [
        {
          relationType: "Employé",
          relationWith: "Ludovine Zoro",
          createdAt: "20 Décembre 2024"
        }
      ]
    }
  ]);
  const [selectedEntreprise, setSelectedEntreprise] = useState({});
  const [copySelectedEntreprise, setCopySelectedEntreprise] = useState({});
  const [entrepriseName, setEntrepriseName] = useState("");
  const [entrepriseFiscalNo, setEntrepriseFiscalNo] = useState("");
  const [email, setEmail] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [textSnackBar, setTextSnackBar] = useState("");
  const [validateForm, setValidateForm] = useState(false);
  const [isUpdatingPhone, setIsUpdatingPhone] = useState(false);
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
  const [mailCopied, setMailCopied] = useState(false);
  const showCopiedMsg = () => {
    setMailCopied(true);
    setTimeout(() => setMailCopied(false), 1000);
  };

  useEffect(() => {
    if (entrepriseName !== "" || email !== "") {
      setValidateForm(true);
    }
  }, [entrepriseName, email]);

  useEffect(() => {
    setCopySelectedEntreprise(JSON.parse(JSON.stringify(selectedEntreprise)));
  }, [selectedEntreprise]);

  const onClickMain = () => {
    setOpenedSidePanelAddEntreprise(true);
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(selectedEntreprise.email);
    showCopiedMsg();
  };

  const sideBarAddentrepriseContent = () => (
    <>
      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Immatricule fiscal"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          onChange={(entreprise) => {
            setEntrepriseFiscalNo(entreprise);
          }}
        />
      </div>
      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Nom de l'entreprise"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          onChange={(entreprise) => {
            setEntrepriseName(entreprise);
          }}
        />
      </div>
      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Adresse e-mail"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          iconLeft={<IoMdMail />}
          onChange={(email) => {
            setEmail(email);
          }}
        />
      </div>
    </>
  );

  useEffect(() => {
    sideBarDisplayEditentrepriseContent();
  }, [selectedEntreprise]);

  const handleEditEntreprise = (entreprise) => {
    if (openSidePanelEditEntreprise) {
      setOpenedSidePanelEditentreprise(false);
    }
    setOpenedSidePanelEditentreprise(true);
    setSelectedEntreprise(entreprise);
  };

  const handleAddEntreprise = () => {
    setEntreprises([
      ...entreprises,
      {
        ref: `ref-${entrepriseName}-000`,
        title: entrepriseName,
        email,
        createdAt: getCurrentDay()
      }
    ]);
    setOpenedSidePanelAddEntreprise(false);
    setTextSnackBar("Une entreprise a été ajoutée avec succès!");
    setShowSnackBar(true);
  };

  const handleUpdateEntreprise = (entreprise) => {
    const updatedItems = entreprises.map((item) => {
      if (item.ref === entreprise.ref) {
        return entreprise;
      } else {
        return item;
      }
    });
    setEntreprises(updatedItems);
    setTextSnackBar("Une entreprise a été modifiée avec succès!");
    setShowSnackBar(true);
  };

  const accordionInfos = () => (
    <div className='columnn gapp-2'>
      <div className='columnn'>
        <span className='text-xs-bold color-neutral-700'>Créé le</span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedEntreprise.createdAt}
        </div>
      </div>
      <div className='columnn'>
        {isUpdatingPhone || selectedEntreprise.hasOwnProperty("phoneNumber") ? (
          <InputWithActions
            value={copySelectedEntreprise.phoneNumber}
            label='Numéro de téléphone'
            button={<MdModeEditOutline />}
            mainEditingButton={<IoCheckmarkOutline size={10} />}
            onClickMain={() => {
              setSelectedEntreprise(
                JSON.parse(JSON.stringify(copySelectedEntreprise))
              );
            }}
            secondEditingButton={<IoCloseOutline size={10} />}
            onClickSecond={() => {
              setCopySelectedEntreprise(
                JSON.parse(JSON.stringify(selectedEntreprise))
              );
              setIsUpdatingPhone(false);
            }}
            onChange={(value) => {
              setCopySelectedEntreprise({
                ...copySelectedEntreprise,
                phoneNumber: value
              });
            }}
            typing={!selectedEntreprise.hasOwnProperty("phoneNumber") && true}
          />
        ) : (
          <div className='columnn'>
            <div className='text-xs-bold color-neutral-700'>
              Numéro de téléphone
            </div>
            <Button
              styled='tertiary'
              label='Ajouter'
              onClick={() => {
                setIsUpdatingPhone(true);
              }}
              endIcon={<FaPlus />}
            />
          </div>
        )}
      </div>
      <div>
        {isUpdatingAddress || selectedEntreprise.hasOwnProperty("address") ? (
          <>
            <InputWithActions
              value={copySelectedEntreprise.address}
              label='Adresse'
              button={<MdModeEditOutline />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                setSelectedEntreprise(
                  JSON.parse(JSON.stringify(copySelectedEntreprise))
                );
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setCopySelectedEntreprise(
                  JSON.parse(JSON.stringify(selectedEntreprise))
                );
                setIsUpdatingAddress(false);
              }}
              onChange={(value) => {
                setCopySelectedEntreprise({
                  ...copySelectedEntreprise,
                  address: value
                });
              }}
              typing={!selectedEntreprise.hasOwnProperty("address") && true}
            />
          </>
        ) : (
          <div>
            <div className='text-xs-bold color-neutral-700'>Adresse</div>
            <Button
              styled='tertiary'
              label='Ajouter'
              onClick={() => {
                setIsUpdatingAddress(true);
              }}
              endIcon={<FaPlus />}
            />
          </div>
        )}
      </div>
    </div>
  );

  const sideBarDisplayEditentrepriseContent = () => (
    <>
      <div className='wrapper'>
        <div>
          <span className='text-sm-semibold color-neutral-600'>
            Réference:{" "}
          </span>
          <span className='text-sm-regular color-neutral-1000'>
            {selectedEntreprise.ref}
          </span>
        </div>
        <div>
          <span>
            <InputWithActions
              value={copySelectedEntreprise.title}
              button={<MdModeEditOutline size={16} />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                setSelectedEntreprise(
                  JSON.parse(JSON.stringify(copySelectedEntreprise))
                );
                handleUpdateEntreprise(copySelectedEntreprise);
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setCopySelectedEntreprise(
                  JSON.parse(JSON.stringify(selectedEntreprise))
                );
              }}
              onChange={(value) => {
                setCopySelectedEntreprise({
                  ...copySelectedEntreprise,
                  title: value
                });
              }}
            />
          </span>
        </div>
        <div className='email'>
          <span className='text-sm-semibold color-neutral-600 label'>
            E-mail:
          </span>
          <span className='mr-2'>
            <InputWithActions
              value={copySelectedEntreprise.email}
              button={<MdModeEditOutline size={16} />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                setSelectedEntreprise(
                  JSON.parse(JSON.stringify(copySelectedEntreprise))
                );
                handleUpdateEntreprise(copySelectedEntreprise);
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setCopySelectedEntreprise(
                  JSON.parse(JSON.stringify(selectedEntreprise))
                );
              }}
              onChange={(value) => {
                setCopySelectedEntreprise({
                  ...copySelectedEntreprise,
                  email: value
                });
              }}
            />
          </span>
          <Button
            styled={"icon"}
            size={"sm"}
            icon={<Link />}
            onClick={copyToClipBoard}
          />
          {mailCopied && (
            <span className='text-xs-regular color-primary-700 mt-1 ml-1'>
              Lien copié !
            </span>
          )}
        </div>
      </div>
      <div className='divider' />
      <div>
        <Accordeon
          title='Informations'
          children={accordionInfos()}
          action={
            <Button size={"sm"} styled='icon' icon={<CgMoreVerticalAlt />} />
          }
          withDesc={true}
          withAction={false}
          isChild={true}
          onAction={() => {}}
          label='Action'
          withdots={true}
        />
        <div className='divider' />
        <Relations
          selectedElement={selectedEntreprise}
          setSelectedElement={setSelectedEntreprise}
          relations={selectedEntreprise.relations}
        />
      </div>
    </>
  );

  return (
    <div className='entreprise-container'>
      {openSidePanelAddEntreprise && (
        <div className='sidepanel'>
          <SidePanel
            title='Ajouter une entreprise'
            onClose={() => setOpenedSidePanelAddEntreprise(false)}
            content={sideBarAddentrepriseContent()}
            primaryButton={
              <Button
                styled='primary'
                label='Ajouter'
                onClick={handleAddEntreprise}
                startIcon={<FaPlus />}
                blocked={!validateForm}
                size={"md"}
              />
            }
            secondaryButton={
              <Button
                styled='tertiary'
                label='Annuler'
                onClick={() => setOpenedSidePanelAddEntreprise(false)}
                size={"md"}
              />
            }
          />
        </div>
      )}

      {openSidePanelEditEntreprise && (
        <div className='sidepanel'>
          <SidePanel
            title={selectedEntreprise.ref}
            category='Entreprises'
            onClose={() => setOpenedSidePanelEditentreprise(false)}
            content={sideBarDisplayEditentrepriseContent()}
            /*  primaryButton={
              <Button
                styled='primary'
                label='Ajouter'
                onClick={handleAddEntreprise}
                endIcon={<FaPlus />}
                blocked={!validateForm}
                size={"md"}
              />
            }
            secondaryButton={
              <Button
                styled='tertiary'
                label='Annuler'
                onClick={() => setOpenedSidePanelAddEntreprise(false)}
                size={"md"}
              />
            } */
            // onClickPrimary={handleUpdateEntreprise}
          />
        </div>
      )}
      {showSnackBar && (
        <Snackbar
          type='success'
          title={textSnackBar}
          size='small'
          autoClose={5000}
          closeCallBack={(state) => {
            setShowSnackBar(state);
          }}
        />
      )}
      <Header
        title={"Entreprises"}
        subtitle={
          "Consultez ici la liste des entreprises et créez des relations entre elles"
        }
        titleIcon={<GoStar />}
        primaryButton={
          entreprises.length > 0 && (
            <Button
              styled='primary'
              label='Ajouter une entreprise'
              onClick={onClickMain}
            />
          )
        }
      />
      <div className='container'>
        <Table
          onRowClick={(entreprise) => {
            handleEditEntreprise(entreprise);
          }}
          withSelect={false}
          pageSize={10}
          setPageSize={() => {}}
          currentPage={1}
          setCurrentPage={() => {}}
          entriesCount={0}
          emptyPageTextButton='Ajouter une entreprise'
          columns={[
            { label: "Référence", key: "ref" },
            { label: "Titre", key: "title" },
            { label: "Nom", key: "name" },
            { label: "E-mail", key: "email" }
          ]}
          data={entreprises}
          emptyPageTitle='Il n’y a aucune entreprise ajoutée dans la plateforme'
          emptyPageDesc='Cliquez sur “Ajouter une entreprise” pour commencer'
          onClickEmptyPage={onClickMain}
          pageSizes={[10, 50]}
        />
      </div>
    </div>
  );
};

export default Companies;
