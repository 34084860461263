import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { GoStar } from "react-icons/go";
import { Header, Table, Snackbar, SidePanel, Input, Select, Button, Accordeon, InputWithActions } from "iosis-storybook/dist/bundle";
import { getCurrentDay } from "../../utils/functions";
import { ReactComponent as LabelSVG } from "../../img/icons/code.svg";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";

const Relations = () => {
  const [openSidePanelAdd, setOpenedSidePanelAdd] = useState(false);
  const [label, setLabel] = useState("")
  const [code, setCode ]= useState("")
  const [relations, setRelations] = useState([]);
  const [textSnackBar, setTextSnackBar] = useState("")
  const [showSnackBar, setShowSnackBar] = useState(false)
  const [validateForm, setValidateForm] = useState(false)
  const [relationsArchived, setRelationsArchived] = useState(false)

  useEffect(()=>{
    if(label!=="" || code!=="" ){
      setValidateForm(true)
    }
  },[label, code ])

  const sideBarAddContent = () =><>  
    <div className="content-input relation-label">
      <div className="label">
        <Input 
          placeholder={"Saisir"}
          label={"Label"}
          type="text"
          description=""
          disabled= {false}
          errorMessage= ""
          required= {false}
          onChange= {(label) => {
            setLabel(label)
          }}
        />
      </div>
      <div className="">
        <LabelSVG onClick={()=>{console.log("clicked");}} className="label-svg"/>
      </div>
      
    </div>
    <div className="content-input">
      <Input 
        placeholder={"Saisir"}
        label={"Code"}
        type="text"
        description=""
        disabled= {false}
        errorMessage= ""
        required= {false}
        onChange={(code) => {
          setCode(code)
        }}
      />
    </div>
  </>

  const handleAddRelation = () =>{
    setRelations([...relations, {id:`ref-${label}-000`, label:label,  code:code, createdAt: getCurrentDay()}])
    setOpenedSidePanelAdd(false)
    setTextSnackBar("Une relation a été ajoutée avec succès!")
    setShowSnackBar(true)
  }
 
  return (
    <div className="relations-container">
      {
          openSidePanelAdd && 
            <SidePanel 
              title="Créer une relation"
              onClose={()=>setOpenedSidePanelAdd(false)}
              content={sideBarAddContent()}
              primaryButton={<Button styled="primary" label="Créer" onClick={handleAddRelation} startIcon={<IoMdAdd size={22}/>} blocked={!validateForm} size={"md"} />}
              secondaryButton={<Button styled="tertiary" label="Annuler" onClick={()=>setOpenedSidePanelAdd(false)} size={"md"}/>}
            />
        }
      {
        showSnackBar && <Snackbar 
          type="success"
          title={textSnackBar}
          size="small"
          autoClose={5000}
          closeCallBack={(state)=>{console.log(state)}}
        />
      }
      {
        relationsArchived && <Snackbar 
          type="success"
          text={"La relation a été archivée avec succès"}
          size="small"
          withLink={true}
          textLink="Annuler"
          autoClose={5000}
          closeCallBack={(state)=>{console.log(state)}}
        />
      }
      <Header title={"Relations"} subtitle={"Créer ici les relations pour pouvoir lier les personnes entre elles"} titleIcon={<GoStar color='#62AF98'/>} primaryButton={relations.length>0 && <Button styled="primary" label="Créer une relation" startIcon={< IoMdAdd size={22}/>} onClick={() => {setOpenedSidePanelAdd(true)}}/>}/>
      <div className="content-wrapper">
        <Table
          onRowClick={() => {
          }}
          withSelect={true}
          pageSize={10}
          setPageSize={() => {}}
          currentPage={1}
          setCurrentPage={() => {}}
          entriesCount={0}
          emptyPageTextButton= "Créer une relation"
          columns={[{ label: "Référence", key: "id" },
            { label: "Label", key: "label" },
            { label: "Code", key: "code" },
            {
              label: "Créé le",
              key: "createdAt"
            },
          ]}
          data={relations}
          emptyPageTitle= "Il n’y a aucune relation crée dans la plateforme"
          emptyPageDesc="Cliquez sur “Créer une relation” pour commencer"
          onClickEmptyPage={()=>{setOpenedSidePanelAdd(true)}}
          pageSizes={[10, 50]}
          onArchive={()=>{setRelationsArchived(true)}}
        />
      </div>
    </div>

  );
};

export default Relations;
