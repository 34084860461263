import React, { useEffect, useState } from "react";
import Button from "../../../../components/ui/Button";
import Input from "../../../../components/ui/Input";
import Dropdown from "../../../../components/ui/Dropdown";
import image from "../../../../img/iosis-logo.svg";
import { MdOutlinePhone, MdMailOutline, MdPhone } from "react-icons/md";
import { HelpBox, Input as Inputt } from "iosis-storybook/dist/bundle";
import {
  addPropertyToPerson,
  createPerson,
  getPersonDetails,
  updatePerson,
} from "../../../personne/services";
import { updateQuote } from "../services";

const PersonalInfo = ({
  setStep,
  quoteId,
  quoteDetails,
  setQuoteUpdated,
  ...props
}) => {
  const [personalData, setPersonalData] = useState({ gender: "MALE" });
  const [additionalData, setAdditionalData] = useState({});

  const handleSubmit = () => {
    !quoteDetails.personId
      ? createPerson(personalData)
          .then((resp) => {
            addPropertyToPerson(resp.data, { data: additionalData });
            updateQuote(quoteId, { personId: resp.data }).then(() => {
              setStep(2);
              setQuoteUpdated((prev) => {
                return !prev;
              });
            });
          })
          .catch((e) => console.log(e))
      : updatePerson({
          ...personalData,
          additionalData: additionalData,
        })
          .then((res) => {
            setStep(2);
          })
          .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (!!quoteDetails.personId) {
      getPersonDetails(quoteDetails.personId)
        .then((res) => {
          setPersonalData(res.data);
          setAdditionalData(res.data.additionalData || {});
        })
        .catch((e) => console.log(e));
    }
  }, [quoteDetails.personId]);
  console.log(personalData);
  return (
    <div className="roww gapp-6">
      <div className="coll-9">
        <div className="roww text-md-medium">
          Étape 1: informations souscripteur
        </div>
        <hr></hr>
        <div className="roww text-sm-semibold color-neutral-600">
          Informations personnelles
        </div>
        <div className="roww gapp-5">
          <div className="coll-6">
            <Inputt
              label="Prénom"
              placeholder="Jean"
              value={personalData.firstName}
              onChange={(value) => {
                setPersonalData({ ...personalData, firstName: value });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Nom"
              placeholder="Dupon"
              value={personalData.lastName}
              onChange={(value) => {
                setPersonalData({ ...personalData, lastName: value });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Adresse e-mail"
              placeholder="thomasriviere@email.com"
              iconLeft={<MdMailOutline />}
              value={personalData.email}
              onChange={(value) => {
                setPersonalData({ ...personalData, email: value });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Numéro de téléphone"
              placeholder="+33 123 1234"
              iconLeft={<MdPhone />}
              value={additionalData.phoneNumber}
              onChange={(value) => {
                setAdditionalData({
                  ...additionalData,
                  phoneNumber: value,
                });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Adresse"
              placeholder="13 rue Ferdinand, Levallois-Perret"
              value={additionalData.address}
              onChange={(value) => {
                setAdditionalData({ ...additionalData, address: value });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Code postal"
              placeholder="92300"
              value={additionalData.postalCode}
              onChange={(value) => {
                setAdditionalData({
                  ...additionalData,
                  postalCode: value,
                });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Date de naissance"
              placeholder="2/12/1988"
              value={additionalData.birthdate}
              onChange={(value) => {
                setAdditionalData({
                  ...additionalData,
                  birthdate: value,
                });
              }}
            />
          </div>
        </div>
        <hr />
        <div className="roww text-sm-semibold color-neutral-600">
          Information permis
        </div>
        <div className="roww gapp-5">
          <div className="coll-6">
            <Inputt
              label="Numéro de permis"
              placeholder="13AA00002"
              value={additionalData.licenseNumber}
              onChange={(value) => {
                setAdditionalData({
                  ...additionalData,
                  licenseNumber: value,
                });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Date d’obtention du permis"
              placeholder="12/12/2013"
              value={additionalData.licenseDate}
              onChange={(value) => {
                setAdditionalData({
                  ...additionalData,
                  licenseDate: value,
                });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt
              label="Lieu d’obtention du permis"
              placeholder="Paris"
              value={additionalData.licensePlace}
              onChange={(value) => {
                setAdditionalData({
                  ...additionalData,
                  licensePlace: value,
                });
              }}
            />
          </div>
          <div className="coll-6">
            <Inputt label="Code postal" placeholder="92300" />
          </div>
        </div>
        {/* <div className="col-8 d-flex flex-column gap-2">
          <div className="d-flex flex-column">
            <span className="text-sm-semibold">Informations personnelles</span>
            <div className="d-flex flex-column gap-4">
              <div className="col-5">
                <Dropdown
                  value={personalData.gender}
                  options={[
                    { id: 1, value: "MALE", label: "Homme" },
                    { id: 2, value: "FEMALE", label: "Femme" },
                  ]}
                  setValue={(value) => {
                    setPersonalData({ ...personalData, gender: value });
                  }}
                />
              </div>

              <div className="d-flex justify-content-between">
                <div className="col-5 d-flex align-items-end">
                  <Input
                    style={{ width: "100%" }}
                    label={"Prénom"}
                    placeholder={"Prénom"}
                    styled={"filled-input"}
                    value={personalData.firstName}
                    setValue={(value) => {
                      setPersonalData({ ...personalData, firstName: value });
                    }}
                  />
                </div>
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Nom"}
                    placeholder={"Nom"}
                    styled={"filled-input"}
                    value={personalData.lastName}
                    setValue={(value) => {
                      setPersonalData({ ...personalData, lastName: value });
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Mail"}
                    placeholder={"Mail"}
                    styled={"filled-input"}
                    value={personalData.email}
                    setValue={(value) => {
                      setPersonalData({ ...personalData, email: value });
                    }}
                  />
                </div>
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Numéro de téléphone"}
                    placeholder={"Numéro de téléphone"}
                    styled={"filled-input"}
                    value={additionalData.phoneNumber}
                    setValue={(value) => {
                      setAdditionalData({
                        ...additionalData,
                        phoneNumber: value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Adresse"}
                    placeholder={"Adresse"}
                    styled={"filled-input"}
                    value={additionalData.address}
                    setValue={(value) => {
                      setAdditionalData({ ...additionalData, address: value });
                    }}
                  />
                </div>
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Code postal"}
                    type={"number"}
                    placeholder={"Code postal"}
                    styled={"filled-input"}
                    value={additionalData.postalCode}
                    setValue={(value) => {
                      setAdditionalData({
                        ...additionalData,
                        postalCode: value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Date de naissance"}
                    placeholder={"Date de naissance"}
                    styled={"filled-input"}
                    value={additionalData.birthdate}
                    setValue={(value) => {
                      setAdditionalData({
                        ...additionalData,
                        birthdate: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column mt-3">
            <h5>Informations permis</h5>
            <hr></hr>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex justify-content-between">
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Numéro de permis"}
                    placeholder={"Numéro de permis"}
                    styled={"filled-input"}
                    value={additionalData.licenseNumber}
                    setValue={(value) => {
                      setAdditionalData({
                        ...additionalData,
                        licenseNumber: value,
                      });
                    }}
                  />
                </div>
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Date d'obtention du permis"}
                    placeholder={"Date d'obtention du permis"}
                    styled={"filled-input"}
                    value={additionalData.licenseDate}
                    setValue={(value) => {
                      setAdditionalData({
                        ...additionalData,
                        licenseDate: value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-5">
                  <Input
                    style={{ width: "100%" }}
                    label={"Lieu d’obtention du permis"}
                    placeholder={"Lieu d’obtention du permis"}
                    styled={"filled-input"}
                    value={additionalData.licensePlace}
                    setValue={(value) => {
                      setAdditionalData({
                        ...additionalData,
                        licensePlace: value,
                      });
                    }}
                  />
                </div>
              </div>
              <hr />
            </div>
          </div>

          <span className="d-flex justify-content-end">
            <Button
              styled="primary"
              title="Enregistrer et continuer"
              onClick={() => {
                handleSubmit();
              }}
            />
          </span>
        </div> */}
      </div>
      <div className="coll-3">
        <HelpBox
          image={image}
          label="Besoin d’aide?"
          description="Vous pouvez contacter nos conseillers pour obtenir votre devis personnalisé"
          buttonText="Appeler"
          buttonIcon={<MdOutlinePhone />}
        />
      </div>
    </div>
  );
};

export default PersonalInfo;
