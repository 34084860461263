import React, { useEffect, useState } from "react";
import { getAllProducts } from "./services";
import {
  Header,
  Button,
  Table,
  Status,
  SidePanel,
  Snackbar
} from "iosis-storybook/dist/bundle";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/utilities";
import { GoStar } from "react-icons/go";
import { FaPlus } from "react-icons/fa6";
import AddProductContent from "./AddProductContent";
import DetailProductContent from "./ProductDetailContent";
const Products = () => {
  const [productList, setProductList] = useState([]);
  const [productDesc, setProductDesc] = useState("");
  const [productName, setProductName] = useState("");
  const [productArchived, setProductArchived] = useState(false);
  const [productShown, setProductShown] = useState({
    add: false,
    detail: false
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const navigate = useNavigate();

  const StatusList = {
    ENABLED: { type: "complete", title: "Activé" },
    ARCHIVED: { type: "danger", title: "Archivé" }
  };
  const columns = [
    {
      key: "id",
      label: "Référence",
      getter: (value) => `${value.slice(0, 8)}`
    },

    {
      key: "name",
      label: "Nom du produit"
    },
    {
      key: "lastUpdate",
      label: "Mis à jour le",
      getter: (value) => formatDate(value)
    },
    {
      key: "status",
      label: "Statut",
      getter: (row) => {
        return (
          <Status
            type={StatusList[row].type}
            title={StatusList[row].title}
            forTable
          />
        );
      }
    },
    {
      key: "id",
      label: "Actions",
      getter: (id) => {
        const currentProduct = productList.find((product) => product.id === id);
        return (
          <Button
            styled={"tertiary"}
            label='Modifier'
            size={"sm"}
            onClick={(event) => {
              event.stopPropagation();
              const selectedProduct = productList.find(
                (product) => product.id === id
              );
              if (selectedProduct) setSelectedProduct(selectedProduct);
              setProductShown(() => ({
                detail: true,
                add: false
              }));
            }}
            disabled={currentProduct?.status === "ARCHIVED"}
          />
        );
      }
    }
  ];

  const onArchive = (itemsId) => {
    setProductList((prevList) =>
      prevList.map((product) =>
        itemsId.includes(product.id)
          ? { ...product, status: "ARCHIVED" }
          : product
      )
    );
    setProductArchived(true);
  };

  useEffect(() => {
    getAllProducts()
      .then((res) => {
        setProductList(res.data);
      })
      .catch((e) => console.log(e));
  }, []);
  return (
    <>
      <div className='product-list-container'>
        {productArchived && (
          <Snackbar
            type='success'
            text='Le produit a été archivé avec succès'
            size='small'
            withLink={true}
            textLink='Annuler'
            autoClose={8000}
          />
        )}
        <Header
          title={"Produits"}
          subtitle={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit donec."
          }
          titleIcon={<GoStar color='#62AF98' />}
          primaryButton={
            productList.length !== 0 && (
              <Button
                styled='primary'
                label='Créer un produit'
                startIcon={<FaPlus />}
                onClick={() => {
                  setProductShown((prevState) => ({
                    ...prevState,
                    add: true
                  }));
                }}
                size={"md"}
              />
            )
          }
        />
        <div className='container'>
          <Table
            pageSize={10}
            columns={columns}
            data={productList}
            entriesCount={productList.length}
            onRowClick={(product) => {
              setProductShown((prevState) => ({
                ...prevState,
                detail: true,
                add: false
              }));
              setSelectedProduct(product);
            }}
            pageSizes={[10, 20, 50]}
            setPageSize={() => {}}
            emptyPageTitle={"Il n’y a aucun produit dans la liste"}
            emptyPageDesc={
              "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum"
            }
            onClickEmptyPage={() => {}}
            emptyPageTextButton={"Créer un produit"}
            withSelect={true}
            onArchive={(items) => {
              onArchive(items);
            }}
          />
        </div>
      </div>
      {productShown.add && (
        <SidePanel
          title='Créer un produit'
          onClose={() =>
            setProductShown((prevState) => ({
              ...prevState,
              add: false
            }))
          }
          content={
            <AddProductContent
              setProductDesc={setProductDesc}
              setProductName={setProductName}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
            />
          }
          primaryButton={
            <Button
              styled={"primary"}
              label={"Créer"}
              size={"md"}
              startIcon={<FaPlus />}
              onClick={() => {
                let product = {
                  id: "0202020202020",
                  name: productName,
                  desc: productDesc,
                  properties: selectedItems,
                  status: "ENABLED"
                };
                setProductList((prevList) => [...prevList, product]);
                setProductShown((prevState) => ({
                  ...prevState,
                  add: false
                }));
                setSelectedItems([]);
              }}
            />
          }
          secondaryButton={
            <Button
              styled={"tertiary"}
              label={"Annuler"}
              size={"md"}
              onClick={() => {
                setProductShown((prevState) => ({
                  ...prevState,
                  add: false
                }));
              }}
            />
          }
        />
      )}
      {productShown.detail && (
        <SidePanel
          category='Produits'
          title={`${selectedProduct.id.slice(0, 8)}`}
          onClose={() =>
            setProductShown((prevState) => ({
              ...prevState,
              detail: false
            }))
          }
          content={<DetailProductContent product={selectedProduct} />}
        />
      )}
    </>
  );
  {
  }
};

export default Products;
