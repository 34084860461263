import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import PersonalInfo from "./PersonalInfo";
import ProductInfo from "./ProductInfo";
import Offers from "./Offers";
import Payment from "./Payment";
import Contract from "./Contract";
import { getQuoteById } from "../services";
import {
  HeaderWithStepper,
  Stepper,
  Button,
} from "iosis-storybook/dist/bundle";
const CreateQuoteAuto = () => {
  const steps = [
    {
      index: "1",
      title: "Informations tiers",
      description: "Description line lorem ipsu",
    },
    {
      index: "2",
      title: "Informations véhicules",
      description: "Description line lorem ipsu",
    },
    { index: "3", title: "Offre", description: "Description line lorem ipsu" },
    {
      index: "4",
      title: "Paiement",
      description: "Description line lorem ipsu",
    },
    {
      index: "5",
      title: "Signature de contrat",
      description: "Description line lorem ipsu",
    },
  ];
  const navigate = useNavigate();
  const { id } = useParams();
  const [step, setStep] = useState(1);
  const [quoteUpdated, setQuoteUpdated] = useState(false);
  const [quoteDetails, setQuoteDetails] = useState({});

  useEffect(() => {
    getQuoteById(id)
      .then((res) => {
        setQuoteDetails(res.data);
      })
      .catch((e) => console.log(e));
  }, [quoteUpdated, id]);
  console.log(quoteDetails);
  return (
    <div className="quote-create-page-container">
      <HeaderWithStepper
        title="Nouveau devis automobile"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit donec."
        primaryButton={
          <Button
            styled="primary"
            label="Aller à l'étape suivante"
            onClick={() => {
              setStep(step + 1);
            }}
          />
        }
        percentage={(step / 5) * 100}
      />
      <div className="quote-content-container">
        <div className="left-side-stepper-container">
          <Stepper steps={steps} actualStep={step} />
          <div
            className="quit-container"
            onClick={() => {
              navigate("/quote_automobile");
            }}
          >
            <FaArrowLeft />
            <span>Quitter</span>
          </div>
        </div>
        <div className="container">
          {step === 1 && (
            <PersonalInfo
              setStep={setStep}
              quoteId={id}
              quoteDetails={quoteDetails}
              setQuoteUpdated={setQuoteUpdated}
            />
          )}
          {step === 2 && (
            <ProductInfo
              setStep={setStep}
              quoteId={id}
              quoteDetails={quoteDetails}
              setQuoteUpdated={setQuoteUpdated}
            />
          )}
          {step === 3 && (
            <Offers
              setStep={setStep}
              quoteDetails={quoteDetails}
              setQuoteUpdated={setQuoteUpdated}
            />
          )}
          {step === 4 && <Payment setStep={setStep} quoteId={id} />}
          {step === 5 && <Contract setStep={setStep} quoteId={id} />}
        </div>
      </div>
    </div>
  );
};

export default CreateQuoteAuto;
