import React, { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import Input from "../../components/ui/Input";
import CustomCheckbox from "../../components/ui/CustomCheckBox";
import { createPricing, updatePricing } from "./services";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40vw",
    padding: "0px",
    minHeight: "42%",
    overflow: "visible",
  },
};

const PricingModal = ({
  updatePricingModalIsOpen,
  setUpdatePricingModalIsOpen,
  handleCloseModal,
  setPricingUpdated,
  prices,
  offerId,
  ...props
}) => {
  const [pricingData, setPricingData] = useState({});

  const handlePricings = (key, value) => {
    setPricingData({
      ...pricingData,
      [key]: { ...pricingData[key], status: value },
    });
  };

  const handlePrices = (key, value) => {
    setPricingData({
      ...pricingData,
      [key]: { ...pricingData[key], price: value },
    });
  };

  const handleSubmit = () => {
    Object.entries(pricingData).map(([key, value], index) => {
      if (!!value?.id) {
        updatePricing(value.id, value)
          .then((resp) => {})
          .catch((e) => console.log(e));
      } else {
        createPricing({
          ...value,
          periodType: key,
          offerId: offerId,
          currency: "EUR",
        })
          .then((resp) => {})
          .catch((e) => console.log(e));
      }
    });
    setTimeout(() => {
      setPricingUpdated((prevState) => {
        return !prevState;
      });
    }, 500);

    handleCloseModal();
  };

  useEffect(() => {
    const Prices = prices.reduce(
      (obj, v) => ({ ...obj, [v.periodType]: v }),
      {}
    );
    setPricingData(Prices);
  }, [prices]);

  return (
    <Modal isOpen={updatePricingModalIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>Modifier la tarification</h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCloseModal();
          }}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-3 h-100">
        <div className="mt-4">
          <div className="d-flex flex-column gap-4">
            <div
              className="d-flex justify-content-between align-items-center w-75"
              style={{ minHeight: "40px" }}
            >
              <div className="d-flex gap-2 align-items-center">
                <CustomCheckbox
                  checked={pricingData?.ONESHOT?.status === "ACTIVE"}
                  onCheckOn={() => {
                    handlePricings("ONESHOT", "ACTIVE");
                  }}
                  onCheckOff={() => {
                    handlePricings("ONESHOT", "ARCHIVE");
                  }}
                />
                <span>Paiement comptant</span>
              </div>

              {!!pricingData?.ONESHOT && (
                <Input
                  styled={"filled-input"}
                  type={"number"}
                  value={pricingData?.ONESHOT.price}
                  setValue={(value) => {
                    handlePrices("ONESHOT", value);
                  }}
                  icon={"€"}
                  disabled={pricingData?.ONESHOT?.status === "ARCHIVE"}
                />
              )}
            </div>

            <div
              className="d-flex justify-content-between align-items-center w-75"
              style={{ minHeight: "40px" }}
            >
              <div className="d-flex gap-2 align-items-center">
                <CustomCheckbox
                  checked={pricingData?.MONTH?.status === "ACTIVE"}
                  onCheckOn={() => {
                    handlePricings("MONTH", "ACTIVE");
                  }}
                  onCheckOff={() => {
                    handlePricings("MONTH", "ARCHIVE");
                  }}
                />
                <span>Paiement mensuel</span>
              </div>

              {!!pricingData?.MONTH && (
                <Input
                  styled={"filled-input"}
                  type={"number"}
                  value={pricingData?.MONTH.price}
                  setValue={(value) => {
                    handlePrices("MONTH", value);
                  }}
                  icon={"€"}
                  disabled={pricingData?.MONTH?.status === "ARCHIVE"}
                />
              )}
            </div>
            <div
              className="d-flex justify-content-between align-items-center w-75"
              style={{ minHeight: "40px" }}
            >
              <div className="d-flex gap-2 align-items-center">
                <CustomCheckbox
                  checked={pricingData?.YEAR?.status === "ACTIVE"}
                  onCheckOn={() => {
                    handlePricings("YEAR", "ACTIVE");
                  }}
                  onCheckOff={() => {
                    handlePricings("YEAR", "ARCHIVE");
                  }}
                />
                <span>Paiement annuel</span>
              </div>

              {!!pricingData?.YEAR && (
                <Input
                  style={{}}
                  styled={"filled-input"}
                  type={"number"}
                  value={pricingData?.YEAR.price}
                  setValue={(value) => {
                    handlePrices("YEAR", value);
                  }}
                  icon={"€"}
                  disabled={pricingData?.YEAR?.status === "ARCHIVE"}
                />
              )}
            </div>

            <div className="d-flex justify-content-end gap-3 mt-auto">
              <Button
                title={"Annuler"}
                styled={"secondary"}
                onClick={() => {
                  handleCloseModal();
                }}
              />
              <Button
                title={"Modifier"}
                styled={"primary"}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PricingModal;
