import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createQuote, getAllQuotes } from "./services";

import { ReactComponent as PenIcon } from "../../../img/icons/pen.svg";
import { ReactComponent as TrashIcon } from "../../../img/icons/trash.svg";
import { formatDate } from "../../../utils/utilities";

import { Header, Button, Table, Status } from "iosis-storybook/dist/bundle";
import { GoStar } from "react-icons/go";
import { FaPlus } from "react-icons/fa6";
const QuoteAutoList = () => {
  const [quotesList, setQuotesList] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });

  const navigate = useNavigate();

  const StatusList = {
    PAID: { type: "complete", title: "Payé" },
    UNPAID: { type: "danger", title: "Impayé" },
    DRAFT: { type: "draft", title: "Brouillon" }
  };

  const ActionCell = (e) => {
    return (
      <div className='button-container'>
        <Button
          styled={"icon"}
          icon={<PenIcon />}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
        <Button
          styled={"icon"}
          icon={<TrashIcon />}
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      </div>
    );
  };

  const columns = [
    { label: "Numéro de devis", key: "id", getter: (e) => e.slice(0, 8) },
    { label: "Créé le", key: "creationDate", getter: (e) => formatDate(e) },
    { label: "Mis à jour le", key: "lastUpdate", getter: (e) => formatDate(e) },
    {
      label: "Statut",
      key: "status",
      getter: (row) => {
        return (
          <Status
            type={StatusList[row].type}
            title={StatusList[row].title}
            forTable={true}
          />
        );
      }
    },
    { label: "Actions", key: "id", getter: (e) => ActionCell(e) }
  ];
  const createBlankQuote = () => {
    createQuote()
      .then((res) => {
        navigate(`/quote/new/${res.data}`);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    getAllQuotes()
      .then((res) => {
        setQuotesList(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <div className='quote-list-container'>
        <Header
          title={"Devis automobile"}
          subtitle={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit donec."
          }
          titleIcon={<GoStar color='#62AF98' />}
          primaryButton={
            quotesList.length !== 0 && (
              <Button
                styled='primary'
                label='Ajouter un devis'
                startIcon={<FaPlus />}
                onClick={() => {
                  createBlankQuote();
                }}
                size={"md"}
              />
            )
          }
        />
        <div className='container'>
          <Table
            pageSize={10}
            columns={columns}
            data={quotesList}
            entriesCount={quotesList.length}
            onRowClick={({ id }) => {
              navigate(`/quote/new/${id}`);
            }}
            pageSizes={[10, 20, 50]}
            setPageSize={() => {}}
            emptyPageTitle={"Il n’y a aucun devis automobile dans la liste"}
            emptyPageDesc={
              "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum"
            }
            onClickEmptyPage={() => createBlankQuote()}
            emptyPageTextButton={"Créer un devis automobile"}
          />
        </div>
      </div>

      {/*  {quotesList.length ? (
          <>
             <div className="d-flex flex-row-reverse">
              <Button
                styled="primary"
                title="Créer un devis"
                type="trailing-icon"
                onClick={() => {
                  createBlankQuote();
                }}
                icon={<FiPlus />}
              />
            </div>

            <div className='flex-row'>
              <DataGrid
                rows={quotesList}
                columns={columns}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                disableColumnMenu
                disableColumnResize
                disableColumnSorting
                disableRowSelectionOnClick
                onRowClick={({ id }) => {
                  navigate(`/quote/new/${id}`);
                }}
              ></DataGrid>
            </div>
          </>
        ) : (
          <div className=' d-flex flex-column align-items-center gap-4 m-auto'>
            <span>Il n’y a actuellement aucun devis dans la liste</span>
            <span>
              <Button
                styled='primary'
                title='Créer un devis'
                type='trailing-icon'
                onClick={() => {
                  createBlankQuote();
                }}
                icon={<FiPlus />}
              />
            </span>
          </div>
        )} */}
    </>
  );
};

export default QuoteAutoList;
