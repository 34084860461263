import React, { useState, useEffect } from "react";
import { FiCheck } from "react-icons/fi";


function CustomCheckbox({
  dataList,
  setDataList,
  value,
  onCheckOn,
  onCheckOff,
  disabled,
  ...props
}) {
  const [checked, setChecked] = useState(props.checked || false);
  const handleCheck = () => {
    if (!disabled) {
      if (!!dataList && !!setDataList) {
        setChecked(!checked);
        if (checked === false) {
          setDataList([...dataList, value]);
        } else {
          var array = [...dataList];
          var index = array.map((el) => el.id).indexOf(value.id);
          if (index !== -1) {
            array.splice(index, 1);
            setDataList(array);
          }
        }
      } else {
        if (!!onCheckOn && !!onCheckOff) {
          if (!checked) {
            onCheckOn();
          } else {
            onCheckOff();
          }
          setChecked(!checked);
        }
      }
    }
  };
  useEffect(() => {
    if (!!props.checked) setChecked(props.checked);
  }, [props.checked]);
  return (
    <>
      <div className={`checkbox ${checked && "checked"}`} onClick={handleCheck}>
        <FiCheck color="white" />
      </div>
    </>
  );
}

export default CustomCheckbox;
