import React, { useState } from "react";
import { createRole } from "../contrat/services";
import { Input, Button } from "iosis-storybook/dist/bundle";

const AddRoleContent = ({
  roleModal,
  setRoleModal,
  setRoleAdded,
  ...props
}) => {
  const [addRoleData, setAddRoleData] = useState({});
  const handleCloseAddModal = () => {
    setRoleModal(false);
    setAddRoleData({});
  };
  const handleSubmit = () => {
    const data = {
      ...addRoleData,
    };
    createRole(data)
      .then(() => {
        handleCloseAddModal();
        setRoleAdded((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => console.log(e));
  };
  return (
    <div className="columnn gapp-2">
      <div className="roww align-flex-end">
        <div className="coll-11">
          <Input
            label="Label"
            placeholder="Saisir"
            onChange={(value) => {
              setAddRoleData({ ...addRoleData, code: value });
            }}
          />
        </div>
        <span className="coll-1 mb-2">
          <Button
            styled="tertiary"
            label="< / >"
            onClick={() => {}}
            size={"md"}
          />
        </span>
      </div>
      <Input
        label="Code"
        placeholder="Saisir"
        onChange={(value) => {
          setAddRoleData({ ...addRoleData, label: value });
        }}
      />
    </div>
  );
};

export default AddRoleContent;
