import { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
//import Button from "../../../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import { MdOutlinePhone, MdDownload } from "react-icons/md";
import { IoEye } from "react-icons/io5";
import { HelpBox, Button, Input as Inputt } from "iosis-storybook/dist/bundle";
import image from "../../../../img/iosis-logo.svg";
import pdfLogo from "../../../../img/pdf-logo.svg";
import doneIcon from "../../../../img/status-dot.svg";

const Contract = ({ setStep, ...props }) => {
  const navigate = useNavigate();
  const [isSigned, setIsSigned] = useState(false);
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    const counter = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    const timer = setTimeout(() => {
      setIsSigned(true);
    }, 10000);

    return () => {
      clearTimeout(timer);
      clearTimeout(counter);
    };
  }, []);

  return (
    <div className="roww gapp-6">
      <div className="coll-9">
        <div className="roww text-md-medium">Étape 5: signature du contrat</div>
        <hr></hr>
        {!isSigned ? (
          <div className="columnn gapp-2 p-10" style={{ textAlign: "center" }}>
            <span className="roww justify-center">
              <img src={doneIcon} />
            </span>
            <span className="roww justify-center text-sm-semibold color-neutral-900">
              Votre paiement a été effectué avec succès
            </span>
            <span className="roww justify-center text-xs-regular color-neutral-900">
              Vous serez dirigé automatiquement vers la signature de votre
              contrat dans {seconds} secondes...
            </span>
            <span className="roww justify-center">
              <Button
                styled="primary"
                label="Passer à la signature"
                onClick={() => {
                  setIsSigned(true);
                }}
              />
            </span>
          </div>
        ) : (
          <div className="columnn gapp-4" style={{ textAlign: "center" }}>
            <div className="roww gapp-2 align-center">
              <img src={doneIcon} />
              <span className="text-sm-semibold color-neutral-900">
                La signature de votre contrat a été effectuée
              </span>
            </div>
            <span className="roww justify-center text-md-bold">
              Merci pour votre confiance !
            </span>
            <div className="columnn">
              <div className="roww justify-center">
                <span className="text-sm-medium color-neutral-900">
                  Votre nouvelle assurance automobile débutera le
                </span>
              </div>
              <div className="roww justify-center">
                <span className="text-sm-medium color-primary-700">
                  12/12/2024
                </span>
              </div>
            </div>
            <div className="columnn gapp-2">
              <div className="roww justify-center text-xs-regular">
                <div className="coll-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. 
                </div>
              </div>
              <div className="roww justify-center text-xs-regular">
                <div className="coll-6">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. 
                </div>
              </div>
            </div>
            <div className="roww justify-center">
              <Button
                styled="primary"
                label="Aller dans la liste des contrats"
                onClick={() => {
                  navigate("/contracts");
                }}
              />
            </div>
            <div className="roww">
              <div className="coll-12 file-container">
                <div className="roww gapp-2 align-center">
                  <img src={pdfLogo} />
                  <span className="text-sm-medium">Contrat n 123456 </span>
                </div>
                <div className="roww gapp-1 align-center">
                  <span>
                    <Button
                      styled={"icon"}
                      icon={<MdDownload />}
                      onClick={() => {}}
                    />
                  </span>
                  <span>
                    <Button
                      styled={"icon"}
                      icon={<IoEye />}
                      onClick={() => {}}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="coll-3">
        <HelpBox
          image={image}
          label="Besoin d’aide?"
          description="Vous pouvez contacter nos conseillers pour obtenir votre devis personnalisé"
          buttonText="Appeler"
          buttonIcon={<MdOutlinePhone />}
        />
      </div>
    </div>
  );
};

export default Contract;
