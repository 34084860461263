import React, { useEffect, useState } from "react";
import {
  Header,
  Table,
  Snackbar,
  SidePanel,
  Input,
  Select,
  Button,
  Accordeon,
  InputWithActions
} from "iosis-storybook/dist/bundle";
import { IoMdMail } from "react-icons/io";
import { GoStar } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import Relations from "./Relations";
import { getCurrentDay } from "../../utils/functions";
import { ReactComponent as Copy } from "../../img/icons/link.svg";
import { CgMoreVerticalAlt } from "react-icons/cg";
const Personnes = () => {
  const [openSidePanelAddPerson, setOpenedSidePanelAddPerson] = useState(false);
  const [openSidePanelEditPerson, setOpenedSidePanelEditPerson] =
    useState(false);
  const [persons, setPersons] = useState([
    {
      ref: "1234567890",
      gender: "Madame",
      firstName: "Doe",
      lastName: "Jane",
      email: "janedoe@gmail.com",
      createdAt: "12 Décembre 2024",
      relations: [
        {
          relationType: "Femme",
          relationWith: "Roronoa Zoro",
          createdAt: "20 Décembre 2024"
        }
      ]
    }
  ]);
  const [selectedPerson, setSelectedPerson] = useState({});
  const [gender, setGender] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [validateForm, setValidateForm] = useState(false);
  const [isUpdatingPhone, setIsUpdatingPhone] = useState(false);
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
  const [copySelectedPerson, setCopySelectedPerson] = useState({});
  const [textSnackBar, setTextSnackBar] = useState("");
  const [mailCopied, setMailCopied] = useState(false);
  const showCopiedMsg = () => {
    setMailCopied(true);
    setTimeout(() => setMailCopied(false), 1000);
  };
  useEffect(() => {
    if (gender !== "" || firstName !== "" || lastName !== "" || email !== "") {
      setValidateForm(true);
    }
  }, [gender, firstName, lastName, email]);

  useEffect(() => {
    setCopySelectedPerson(JSON.parse(JSON.stringify(selectedPerson)));
  }, [selectedPerson]);

  const onClickMain = () => {
    setOpenedSidePanelAddPerson(true);
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(selectedPerson.email);
    showCopiedMsg();
  };

  const handleUpdatePerson = (person) => {
    const updatedItems = persons.map((item) => {
      if (item.ref === person.ref) {
        return person;
      } else {
        return item;
      }
    });
    setPersons(updatedItems);
    setTextSnackBar("Une personne a été modifiée avec succès!");
    setShowSnackBar(true);
  };

  const handleEditPerson = (person) => {
    if (openSidePanelEditPerson) {
      setOpenedSidePanelEditPerson(false);
    }
    setOpenedSidePanelEditPerson(true);
    setSelectedPerson(person);
  };

  const sideBarAddPersonContent = () => (
    <>
      <div className='content-input'>
        <Select
          label={"Genre"}
          dataList={[
            { id: 1, value: "Monsieur", label: "Monsieur" },
            { id: 2, value: "Madame", label: "Madame" }
          ]}
          placeholder={"Sélectionner"}
          disabled={false}
          onSelectItem={(el) => {
            setGender(el.value);
          }}
        />
      </div>

      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Prénom"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          onChange={(firstName) => {
            setFirstName(firstName);
          }}
        />
      </div>
      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Nom"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          onChange={(lastName) => {
            setLastName(lastName);
          }}
        />
      </div>
      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Adresse e-mail"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          iconLeft={<IoMdMail />}
          onChange={(email) => {
            setEmail(email);
          }}
        />
      </div>
    </>
  );

  const handleAddPerson = () => {
    setPersons([
      ...persons,
      {
        ref: `ref-${firstName}-000`,
        gender,
        firstName,
        lastName,
        email,
        createdAt: getCurrentDay()
      }
    ]);
    setOpenedSidePanelAddPerson(false);
    setTextSnackBar("Une personne a été ajoutée avec succès!");
    setShowSnackBar(true);
  };

  const accordionInfos = () => (
    <>
      <div>
        <span className='text-xs-bold color-neutral-700'>Créé le</span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedPerson.createdAt}
        </div>
      </div>
      <div>
        {isUpdatingPhone ||
        (selectedPerson.hasOwnProperty("phoneNumber") &&
          selectedPerson.phoneNumber !== "") ? (
          <InputWithActions
            value={copySelectedPerson.phoneNumber}
            label='Numéro de téléphone'
            button={<MdModeEditOutline />}
            mainEditingButton={<IoCheckmarkOutline size={10} />}
            onClickMain={() => {
              setSelectedPerson(JSON.parse(JSON.stringify(copySelectedPerson)));
              setIsUpdatingPhone(false);
            }}
            secondEditingButton={<IoCloseOutline size={10} />}
            onClickSecond={() => {
              setCopySelectedPerson(JSON.parse(JSON.stringify(selectedPerson)));
              setIsUpdatingPhone(false);
            }}
            onChange={(value) => {
              setCopySelectedPerson({
                ...copySelectedPerson,
                phoneNumber: value
              });
            }}
            typing={!selectedPerson.hasOwnProperty("phoneNumber") && true}
          />
        ) : (
          <div>
            <div className='text-xs-bold color-neutral-700'>
              Numéro de téléphone
            </div>
            <Button
              styled='tertiary'
              label='Ajouter'
              onClick={() => {
                setIsUpdatingPhone(true);
              }}
              endIcon={<IoMdAdd />}
            />
          </div>
        )}
      </div>
      <div>
        {isUpdatingAddress ||
        (selectedPerson.hasOwnProperty("address") &&
          selectedPerson.address !== "") ? (
          <>
            <InputWithActions
              value={copySelectedPerson.address}
              label='Adresse'
              button={<MdModeEditOutline />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                setSelectedPerson(
                  JSON.parse(JSON.stringify(copySelectedPerson))
                );
                setIsUpdatingAddress(false);
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setCopySelectedPerson(
                  JSON.parse(JSON.stringify(selectedPerson))
                );
                setIsUpdatingAddress(false);
              }}
              onChange={(value) => {
                setCopySelectedPerson({
                  ...copySelectedPerson,
                  address: value
                });
              }}
              typing={!selectedPerson.hasOwnProperty("address") && true}
            />
          </>
        ) : (
          <div>
            <div className='text-xs-bold color-neutral-700'>Adresse</div>
            <Button
              styled='tertiary'
              label='Ajouter'
              onClick={() => {
                setIsUpdatingAddress(true);
              }}
              endIcon={<IoMdAdd />}
            />
          </div>
        )}
      </div>
      <div>
        <span className='text-xs-bold color-neutral-700'>
          Date de naissance
        </span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedPerson.createdAt}
        </div>
      </div>
    </>
  );

  const sideBarDisplayEditPersonContent = () => (
    <>
      <div className='wrapper'>
        <div>
          <span className='text-sm-semibold color-neutral-600'>
            Référence:{" "}
          </span>
          <span className='text-sm-regular color-neutral-1000'>
            {selectedPerson.ref}
          </span>
        </div>
        <div>
          <span className='name text-lg-medium color-neutral-800'>
            {selectedPerson.gender === "Madame" ? "Me" : "Mr "}
          </span>
          <span className='name text-lg-medium color-neutral-800'>
            {selectedPerson.firstName + " " + selectedPerson.lastName}
          </span>
        </div>
        <div className='email'>
          <span className='text-sm-semibold color-neutral-600 label'>
            E-mail:{" "}
          </span>
          <span className='mr-2'>
            <InputWithActions
              value={copySelectedPerson.email}
              button={<MdModeEditOutline size={16} />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                setSelectedPerson(
                  JSON.parse(JSON.stringify(copySelectedPerson))
                );
                handleUpdatePerson(copySelectedPerson);
                // setIsUpdatingPhone(false)
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setCopySelectedPerson(
                  JSON.parse(JSON.stringify(selectedPerson))
                );
                // setIsUpdatingPhone(false)
              }}
              onChange={(value) => {
                setCopySelectedPerson({ ...copySelectedPerson, email: value });
              }}
            />
          </span>
          <Button
            styled={"icon"}
            size={"sm"}
            icon={<Copy />}
            onClick={copyToClipBoard}
          />
          {mailCopied && (
            <span className='text-xs-regular color-primary-700 mt-1 ml-1'>
              Lien copié !
            </span>
          )}
        </div>
      </div>
      <div className='divider' />
      <div>
        <Accordeon
          title={"Informations"}
          children={accordionInfos()}
          action={
            <Button size={"sm"} styled='icon' icon={<CgMoreVerticalAlt />} />
          }
          isChild={true}
        />
      </div>
      <div className='divider' />
      <Relations
        selectedElement={selectedPerson}
        setSelectedElement={setSelectedPerson}
        relations={selectedPerson.relations}
      />
    </>
  );

  return (
    <div className='personnes-container'>
      {openSidePanelAddPerson && (
        <SidePanel
          title='Ajouter une personne'
          onClose={() => setOpenedSidePanelAddPerson(false)}
          content={sideBarAddPersonContent()}
          primaryButton={
            <Button
              styled='primary'
              label='Ajouter'
              onClick={handleAddPerson}
              startIcon={<IoMdAdd size={22}/>}
              blocked={!validateForm}
              size={"md"}
            />
          }
          secondaryButton={
            <Button
              styled='tertiary'
              label='Annuler'
              onClick={() => setOpenedSidePanelAddPerson(false)}
              size={"md"}
            />
          }
        />
      )}

      {openSidePanelEditPerson && (
        <SidePanel
          title={selectedPerson.ref}
          category='Personnes'
          onClose={() => setOpenedSidePanelEditPerson(false)}
          content={sideBarDisplayEditPersonContent()}
          onClickPrimary={handleUpdatePerson}
        />
      )}
      {showSnackBar && (
        <Snackbar
          type='success'
          title={textSnackBar}
          size='small'
          autoClose={5000}
          closeCallBack={(state) => {
            console.log(state);
          }}
        />
      )}
      <Header
        title={"Personnes"}
        subtitle={
          "Consultez ici la liste des personnes et créez des relations entre elles"
        }
        titleIcon={<GoStar color='#62AF98'/>}
        primaryButton={
          persons.length > 0 && (
            <Button
              styled='primary'
              label='Ajouter une personne'
              onClick={onClickMain}
            />
          )
        }
      />
      <div className='content-wrapper'>
        <Table
          onRowClick={(person) => {
            handleEditPerson(person);
          }}
          withSelect={false}
          pageSize={10}
          setPageSize={() => {}}
          currentPage={1}
          setCurrentPage={() => {}}
          entriesCount={0}
          emptyPageTextButton='Ajouter une personne'
          columns={[
            { label: "Référence", key: "ref" },
            { label: "Genre", key: "gender" },
            { label: "Prénom", key: "firstName" },
            { label: "Nom", key: "lastName" },
            { label: "E-mail", key: "email" },
            {
              label: "Créé le",
              key: "createdAt"
            }
          ]}
          data={persons}
          emptyPageTitle='Il n’y a aucune personne ajoutée dans la plateforme'
          emptyPageDesc='Cliquez sur “Ajouter une personne” pour commencer'
          onClickEmptyPage={onClickMain}
          pageSizes={[10, 50]}
        />
      </div>
    </div>
  );
};

export default Personnes;
