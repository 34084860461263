import React, { useState, useEffect } from "react";
import {
  Input,
  Checkbox,
  Select,
  SwitchButton
} from "iosis-storybook/dist/bundle";
const AddPropertyContent = ({ setPropertyLabel, setPropertyCode }) => {
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [charLength, setCharLength] = useState({ min: "", max: "" });
  const [decimalLength, setDecimalLength] = useState();
  const [isNegative, setIsNegative] = useState(false);
  const entites = [
    {
      id: 1,
      label: "Personnes"
    },
    {
      id: 2,
      label: "Entreprises"
    },
    {
      id: 3,
      label: "Contrats"
    }
  ];
  const dataList = [
    { id: 1, value: "String", label: "Chaine de caractere" },
    { id: 2, value: "Number", label: "Nombre" },
    { id: 3, value: "email", label: "E-mail" },
    { id: 4, value: "phone_number", label: "Numéro de téléphone" },
    { id: 5, value: "boolean", label: "Booléan" }
  ];
  const handleMultipleSelect = (item) => {
    setSelectedEntities((prevSelectedEntities) => {
      const isSelected = prevSelectedEntities.some(
        (selectedItem) => selectedItem.id === item.id
      );
      if (isSelected) {
        return prevSelectedEntities.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else {
        return [...prevSelectedEntities, item];
      }
    });
  };

  const renderAdditionalInputs = () => {
    switch (selectedType) {
      case "String":
        return (
          <div className='roww align-center justify-space-between'>
            <Input
              label='Nombre de caractère'
              placeholder='Minimum'
              type='number'
              onChange={(n) =>
                setCharLength((prev) => ({
                  ...prev,
                  min: n
                }))
              }
            />
            <span className='property-sep'>-</span>
            <Input
              placeholder='Maximum'
              type='number'
              onChange={(n) => {
                setCharLength((prev) => ({
                  ...prev,
                  max: n
                }));
              }}
            />
          </div>
        );
      case "Number":
        return (
          <div className='property-form-container'>
            <div className='roww align-center justify-space-between'>
              <Input
                label='Nombre de chiffre'
                placeholder='Minimum'
                type='number'
                onChange={(n) =>
                  setCharLength((prev) => ({
                    ...prev,
                    min: n
                  }))
                }
              />
              <span className='property-sep'>-</span>
              <Input
                placeholder='Maximum'
                type='number'
                onChange={(n) => {
                  setCharLength((prev) => ({
                    ...prev,
                    max: n
                  }));
                }}
              />
            </div>
            <Input
              label='Nombre de chiffre'
              placeholder='Sélectionner'
              type='number'
              onChange={(value) => {
                setDecimalLength(value);
              }}
            />
            <div className='roww align-center justify-space-between'>
              <span className='text-xs-bold color-neutral-700'>
                Autoriser les nombres négatifs
              </span>
              <SwitchButton
                simple
                onSwitch={() => {
                  setIsNegative((prev) => !prev);
                }}
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div className='property-form-container'>
      <Input
        label='Label'
        placeholder='Saisir'
        type='text'
        onChange={(label) => {
          setPropertyLabel(label);
        }}
      />
      <Input
        label='Code'
        placeholder='Saisir'
        type='text'
        onChange={(code) => {
          setPropertyCode(code);
        }}
      />
      <div className='columnn gapp-1'>
        <span className='text-xs-bold color-neutral-700'>
          Ajouter cette propriété dans
        </span>
        <div className='roww align-center gapp-2'>
          {entites.map((entity, index) => (
            <Checkbox
              label={entity.label}
              onChange={() => handleMultipleSelect(entity)}
              checked={selectedEntities.some((el) => el.id === entity.id)}
            />
          ))}
        </div>
      </div>
      <Select
        label='Type de propriété'
        placeholder='Sélectionner'
        dataList={dataList}
        onSelectItem={(el) => {
          setSelectedType(el.value);
        }}
      />
      {renderAdditionalInputs()}
    </div>
  );
};

export default AddPropertyContent;
