import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import Button from "../../../../components/ui/Button";
import { HelpBox, Input as Inputt } from "iosis-storybook/dist/bundle";
import image from "../../../../img/iosis-logo.svg";
import visa from "../../../../img/icons/VISA.svg";
import mastercard from "../../../../img/icons/Mastercard.svg";
import amex from "../../../../img/icons/AMEX.svg";
import { MdOutlinePhone } from "react-icons/md";

const Payment = ({ setStep, ...props }) => {
  const bankLogos = (
    <div className='bank-logos'>
      <img src={visa} />
      <img src={mastercard} />
      <img src={amex} />
    </div>
  );
  return (
    <div className='roww gapp-6'>
      <div className='coll-9'>
        <div className='roww align-center'>
          <FaArrowLeft
            size={16}
            color='#3D7D65'
            className='mr-3'
            onClick={() => {
              setStep(3);
            }}
          />

          <span className='text-md-medium'>Étape 4: paiement</span>
        </div>
        <hr></hr>
        <div className='columnn gapp-4'>
          <div className='roww text-sm-semibold color-neutral-600'>
            Devis numéro:
          </div>
          <div className='offer-proposal-container'>
            <div className='coll-4 columnn'>
              <span className='color-neutral-700 text-sm-semibold'>
                Offre basique
              </span>
              <div className='roww gapp-2 align-center'>
                <span className='color-primary-700 h3-header-bold'>1200 €</span>
                <span className='color-neutral-400 text-sm-medium'>/An</span>
              </div>
            </div>
            <div className='roww coll-8 gapp-4'>
              <div className='columnn'>
                <span className='text-md-semibold color-neutral-700'>
                  Garanties prises en charge
                </span>
                <span>Bris de glace, vol,vandalisme</span>
              </div>
              <div className='columnn'>
                <span className='text-md-semibold color-neutral-700'>
                  Assistance technique 24/7{" "}
                </span>
                <span>Oui</span>
              </div>
            </div>
          </div>
          <div className='roww gapp-5 justify-space-between align-flex-end'>
            <div className='coll-12'>
              <Inputt
                label='Numéro de la carte'
                placeholder='1234 1234 1234 1234'
                iconRight={bankLogos}
              />
            </div>
            <div className='coll-6'>
              <Inputt label='Expire le' placeholder='MM / YY' />
            </div>
            <div className='coll-6'>
              <Inputt label='CVC' placeholder='CVC' />
            </div>
            <div className='coll-12'>
              <Inputt label='Nom sur la carte' placeholder='Nom Prenom' />
            </div>
          </div>
        </div>
      </div>
      <div className='coll-3'>
        <HelpBox
          image={image}
          label='Besoin d’aide?'
          description='Vous pouvez contacter nos conseillers pour obtenir votre devis personnalisé'
          buttonText='Appeler'
          buttonIcon={<MdOutlinePhone />}
        />
      </div>
    </div>
  );
};

export default Payment;
