const formatDate = (value, style = "long") => {
  const date = new Date(value * 1000);
  return value ? date.toLocaleDateString("fr-FR", { dateStyle: style }) : "-";
};

const getInitials = (firstName, lastName) => {
  const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
  const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
  return firstInitial + lastInitial;
};
export { formatDate, getInitials };
